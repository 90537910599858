;
(function (undefined) {
    var MASK_CHARARACTER = '_';

    Shop.InputMask = function (options) {
        this.options = options || {
            $el: null,
            mask: null,
            pattern: null,
            validPattern: null,
            value: null
        };

        this.options.enabled = false;
        this.patternCheck = false;
        this.patternRegexp = false;
        this.keyDownValue = null;
        this.lastPos = false;

        this.skipBy = null;
        this.skipCharacters = null;
        this.skipKeys = [
            'Backspace',
            'Enter',
            'Escape',
            'ArrowLeft',
            'ArrowUp',
            'ArrowRight',
            'ArrowDown',
            'Delete',
            'CapsLock',
            'Shift',
            'Control',
            'Alt',
            'AltGraph',
            'Tab'
        ];

        this.events = {
            mousedown: null,
            mouseup: null,
            blur: null,
            focus: null,
            keydown: null,
            input: null,
            keyup: null,
            paste: null
        };

        this.initialize();
    };

    Shop.InputMask.prototype = {
        constructor: Shop.MaskInput,

        initialize: function () {
            var self = this;
            var pattern = this.options.pattern;
            var maskRegexp = new RegExp(MASK_CHARARACTER, 'g');

            this.skipCharacters = _uniqueArray(this.options.mask.replace(maskRegexp, '').split(''));

            if (pattern) {
                this.patternRegexp = new RegExp(this.options.pattern, 'g');
                this.patternCheck = true;
            }

            this.events.mousedown = function (ev) {

                _mousedown.call(self, ev);
            };
            this.events.mouseup = function (ev) {

                _mouseup.call(self, ev);
            };
            this.events.blur = function (ev) {

                _blur.call(self, ev);
            };
            this.events.focus = function (ev) {

                _focus.call(self, ev);
            };
            this.events.keydown = function (ev) {

                _keydown.call(self, ev);
            };
            this.events.input = function (ev) {

                _input.call(self, ev);
            };
            this.events.keyup = function (ev) {

                _keyup.call(self, ev);
            };
            this.events.paste = function (ev) {

                _paste.call(self, ev);
            };

            this.enable();
        },

        setCursorPosition: function (posStart, posEnd) {
            var el = this.options.$el[0];

            var start;
            var end;

            posStart = posStart ? posStart : 0;
            posEnd = posEnd ? posEnd : posStart;

            if (typeof posStart !== 'number') {
                start = posStart[0];
                end = posStart[1];
            } else {
                start = posStart;
                end = posEnd;
            }

            el.selectionStart = start;
            el.selectionEnd = end;
        },

        getCursorPosition: function () {
            var el = this.options.$el[0];
            return [el.selectionStart, el.selectionEnd];
        },

        enable: function () {
            if (this.options.enabled === false) {
                this.options.$el
                    .attr('autocomplete', 'off')
                    .on('mousedown', this.events.mousedown)
                    .on('mouseup', this.events.mouseup)
                    .on('blur', this.events.blur)
                    .on('focus', this.events.focus)
                    .on('keydown', this.events.keydown)
                    .on('input', this.events.input)
                    .on('keyup', this.events.keyup)
                    .on('paste', this.events.paste)
                    .val((!!this.options.value ? this.options.value : ''));

                this.options.enabled = true;
            }
        },

        disable: function () {
            if (this.options.enabled === true) {
                this.options.$el
                    .attr('autocomplete', 'on')
                    .off('mousedown', this.events.mousedown)
                    .off('mouseup', this.events.mouseup)
                    .off('blur', this.events.blur)
                    .off('focus', this.events.focus)
                    .off('keydown', this.events.keydown)
                    .off('input', this.events.input)
                    .off('keyup', this.events.keyup)
                    .off('paste', this.events.paste);

                this.options.enabled = false;
            }
        }
    };

    function _uniqueArray(arr) {
        var a = [];
        var arrLength = arr.length;
        var i;
        for (i = 0; i < arrLength; i++) {
            if (a.indexOf(arr[i]) === -1 && arr[i] !== '') {
                a.push(arr[i]);
            }
        }

        return a;
    }

    function _mousedown(ev) {
        if (!ev.target.value) {
            ev.target.value = this.options.mask;
            this.setCursorPosition();
        }
    }

    function _focus(ev) {
        _mousedown.call(this, ev);
    }

    function _mouseup(ev) {
        if (ev.target.value === this.options.mask) {
            this.setCursorPosition();
        }
    }

    function _blur(ev) {
        if (ev.target.value === this.options.mask) {
            ev.target.value = '';
        }
    }

    function _keydown(ev) {
        var value = ev.target.value;
        var currentPosition = this.getCursorPosition();
        var newValue;
        var startSubstring;
        var middleSubstring;
        var endSubstring;
        var deleteRegexp;
        var self;

        /**
         * repeat property on this -> fallback for not supporting repeat event poperty on ie11/edge
         */
        if (ev.originalEvent.repeat || this.repeat) {
            if (ev.key === 'Backspace' || ev.keyCode === 8) {
                ev.target.value = this.options.mask;
                this.setCursorPosition();
            } else if (ev.key === 'Delete' || ev.keyCode === 46) {
                ev.target.value = this.options.mask;
                this.keydownValue = this.options.mask;
                this.firstPos = true;
                this.setCursorPosition();
            } else {
                this.keydownValue = value;
            }

            return false;
        }

        this.repeat = true;

        /**
         *selection more than one character was made
         */
        if (currentPosition[0] !== currentPosition[1]) {
            if (ev.key === 'Backspace' || ev.key === 'Delete' || ev.keyCode === 8 || ev.keyCode === 46) {
                startSubstring = value.substring(0, currentPosition[0]);
                middleSubstring = value.substring(currentPosition[0], currentPosition[1]);
                endSubstring = value.substring(currentPosition[1], value.length);
                deleteRegexp = new RegExp('[^' + this.skipCharacters.join('') + ']', 'g');

                newValue = startSubstring + middleSubstring.replace(deleteRegexp, MASK_CHARARACTER) + endSubstring;
                ev.target.value = newValue;
                this.keydownValue = newValue;

                if (ev.key === 'Backspace' || ev.keyCode === 8) {
                    this.setCursorPosition(currentPosition[0] + 2);
                    if (currentPosition[0] == 5) {
                        this.lastPos = true;
                    }
                } else {
                    this.setCursorPosition(currentPosition[0]);
                }
                return false;
            } else if (!_areKeysCombined(ev) && this.skipKeys.indexOf(ev.key) < 0) {
                currentPosition = [currentPosition[0], currentPosition[0]];
            }
        }

        /**
         * Added keyCode for older browser compatibility, keyCode 8 == backspace, keyCode 46 === delete
         */
        if (ev.key === 'Backspace' || ev.key === 'Delete' || ev.keyCode === 8 || ev.keyCode === 46) {
            if (ev.key === 'Backspace' || ev.keyCode === 8) {
                if (this.skipCharacters.indexOf(value[currentPosition[0] - 1]) >= 0) { // skip special characaters that are part of the mask
                    this.keydownValue = ev.target.value;
                    this.setCursorPosition(currentPosition[0] + 1);
                    return false;
                }

                if (currentPosition[0] !== 0) { // do nothing if cursor is at the start of the input
                    ev.target.value = _insertCharAt(value, MASK_CHARARACTER, currentPosition[0]);
                    this.setCursorPosition(currentPosition);
                }
            }

            if (ev.key === 'Delete' || ev.keyCode === 46) {
                if (this.skipCharacters.indexOf(value[currentPosition[0]]) >= 0) { // skip special characaters that are part of the mask
                    this.keydownValue = ev.target.value;
                    this.setCursorPosition(currentPosition[0] + 1);
                    return false;
                }

                if (currentPosition[0] !== value.length) {
                    ev.target.value = _replaceCharAt(value, MASK_CHARARACTER, currentPosition[0]);
                    this.keydownValue = ev.target.value;
                    setTimeout(function () {
                        this.setCursorPosition(currentPosition.map(function (position) {
                            return position += 1;
                        }));
                    }.bind(this), 0);
                    return false;
                }
            }

            return true;
        }

        if (this.skipKeys.indexOf(ev.key) >= 0 || _areKeysCombined(ev)) { // no special behaviour for selected keys
            return true;
        }

        self = this;
        if (this.skipBy > 0) {
            this.setCursorPosition(currentPosition.map(function (position) {
                return position += self.skipBy;
            }));
        } else {
            currentPosition[1] += 1;

            if (window.navigator.userAgent.indexOf("Edge/") > -1) {
                ev.target.value = _replaceCharAt(ev.target.value, ev.key, currentPosition[0]);
            }

            this.setCursorPosition(currentPosition);
        }

        if (this.skipCharacters.indexOf(value[currentPosition[0]]) >= 0) {
            this.skipBy = _skipCharactersBy.call(this, value, currentPosition[0], 0);

            this.setCursorPosition(currentPosition.map(function (position) {
                return position += self.skipBy;
            }));
        }
        this.skipBy = _skipCharactersBy.call(this, value, currentPosition[1], 0);
        this.keydownValue = value;
    }

    function _input(ev) {
        var mask = this.options.mask;
        var value = ev.target.value;
        var isValid = _isValidPattern.call(this, value);
        var currentPosition;
        var lastMaskCharacterPos;

        if (isValid === false) {
            currentPosition = this.getCursorPosition();
            ev.target.value = this.keydownValue;
            if (this.lastPos || this.firstPos) {
                this.setCursorPosition(currentPosition[0]);
                this.lastPos = false;
                this.firstPos = false;
            } else {
                this.setCursorPosition(currentPosition[0] - 1);
            }
            return false;
        }

        if (mask.length !== value.length) {
            ev.target.value = value.slice(0, mask.length);

            lastMaskCharacterPos = value.indexOf(MASK_CHARARACTER);
            if (lastMaskCharacterPos >= 0) {
                this.setCursorPosition(lastMaskCharacterPos);
            }
        }
    }

    function _keyup(ev) {
        var value = ev.target.value;
        var currentPosition = this.getCursorPosition();
        var skipBy;
        this.skipBy = 0;
        this.repeat = false;

        if (ev.key === 'Backspace' || ev.keyCode === 8) {
            skipBy = _skipCharactersBy.call(this, value, currentPosition[0] - 1, 0, true);

            if (skipBy > 0) {
                this.setCursorPosition(currentPosition.map(function (position) {
                    return position -= skipBy;
                }));
            }
            return true;
        }

        if (ev.key === 'Delete' || ev.keyCode === 46) {
            skipBy = _skipCharactersBy.call(this, value, currentPosition[0], 0);
            if (skipBy > 0) {
                this.setCursorPosition(currentPosition.map(function (position) {
                    return position += skipBy;
                }));
            }
            return true;
        }

        if (this.skipKeys.indexOf(ev.key) >= 0 || _areKeysCombined(ev)) {
            return true;
        }

        if (!value) {
            ev.target.value = this.options.mask;
            this.setCursorPosition();
        }

        skipBy = _skipCharactersBy.call(this, value, currentPosition[0], 0);
        if (skipBy > 0 && value.length !== currentPosition[0] + skipBy) {
            this.setCursorPosition(currentPosition.map(function (position) {
                return position += skipBy;
            }));
        }
    }

    function _paste(ev) {
        var value = ev.target.value;
        var valueLength = value.length;
        var pastedValue = ev.originalEvent.clipboardData.getData('Text');
        var currentPosition = this.getCursorPosition();
        var newValue = '';
        var i;
        var j;
        ev.preventDefault();

        for (i = 0, j = 0; i < valueLength; i += 1) {
            if (i >= currentPosition[0] && pastedValue[j] && this.skipCharacters.indexOf(value[i]) < 0) {
                if (this.skipCharacters.indexOf(pastedValue[j]) < 0) {
                    newValue = newValue + pastedValue[j];
                } else {
                    i -= 1;
                }
                j += 1;
            } else {
                newValue = newValue + value[i];
            }
        }

        if (_isValidPattern.call(this, newValue)) {
            ev.target.value = newValue;
            this.setCursorPosition(ev.target.value.indexOf(MASK_CHARARACTER));
        }
    }

    function _skipCharactersBy(value, position, skipBy, backward) {
        backward = backward ? backward : false;
        if (this.skipCharacters.indexOf(value[position]) >= 0) {
            if (backward === true) {
                position -= 1;
            } else {
                position += 1;
            }

            skipBy += 1;
            return _skipCharactersBy.call(this, value, position, skipBy, backward);
        }

        return skipBy;
    }

    function _insertCharAt(string, char, position) {
        return string.slice(0, position) + char + string.slice(position);
    }

    function _replaceCharAt(string, char, position) {
        return string.substr(0, position) + char + string.substr(position + 1);
    }

    function _areKeysCombined(ev) {
        return ev.altKey === true || ev.ctrlKey === true || ev.metaKey === true;
    }

    function _isValidPattern(value) {
        var validPattern;
        var valueLength;
        var partialValue;
        var i;
        if (this.patternCheck === true) {
            validPattern = this.options.validPattern;
            valueLength = value.length;
            partialValue = '';

            for (i = 0; i < valueLength; i += 1) {
                partialValue += (value[i] !== '_' ? value[i] : validPattern[i]);
            }

            if (partialValue.match(this.patternRegexp) === null) {
                return false;
            }

            return true;
        }

        return true;
    }
})();