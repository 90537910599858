import customerPrivacy from '@frontstoreRwd/modules/customer_privacy/index';

(function(undefined) {
    var SEARCH_SETTINGS;
    var XHR_ALL_RESULTS_DISABLED;
    var SEARCH_OPTIONS = {
        delay: 400,
        maxRepeat: 3,
        requestRepeatDelay: 200,
        cacheSameInputMarkup: 30000
    };

    Shop.Search = function(options) {
        if (!SEARCH_SETTINGS) {
            SEARCH_SETTINGS = JSON.parse(this.parent.base64.decode($('.js__search-settings').attr('value')));

            XHR_ALL_RESULTS_DISABLED = !(
                SEARCH_SETTINGS.PRODUCERS_ENABLED ||
                SEARCH_SETTINGS.CATEGORIES_ENABLED ||
                SEARCH_SETTINGS.PRODUCTS_ENABLED
            );
        }

        this.options = options || {
            $el: null,
            baseDir: '',
            onSearchOpen: null,
            onSearchClose: null,
            mobileBtn: false
        };

        this.callbacks = {
            onSearchContentScroll: null
        };

        this.elements = {
            $search: this.options.$el,
            $input: null
        };

        this.objects = {
            view: null,
            startPhrases: null,
            history: null,
            cache: null
        };

        this.settings = {
            twoColumnResultsView: false
        };

        /**
         * Current request attempts
         */
        this.attempts = {
            results: 0,
            suggestions: 0,
            products: 0,
            producers: 0
        };

        this.requests = [];
        this.requestsPending = false;
        this.requestTimeout = null;
        this.dataReturned = 0;
        this.productKeyUrlMaps = {};

        this.inputValue = null;
        /**
         * contains input phrase which was written by user, input value can by changed durign moving through suggesions,
         * but we want to save to history phrase written by user.
         */
        this.origPhrase = null;
        this.inputValueSetTime = null;
        this.firstSuggestion = null;

        this.initialize();
    };

    Shop.Search.prototype = {
        constructor: Shop.Search,

        parent: Shop.prototype,

        initialize: function() {
            var self = this;
            var searchPhrase;
            var searchQueryRegExp;
            var inputValue;

            var baseURL = self.parent.substitute('{baseDir}/webapi/front/{lang}/search', {
                lang: Shop.lang.name,
                baseDir: self.options.baseDir
            });

            if (!Shop.Search.SEARCH_GLOBAL_SETTINGS) {
                Shop.Search.SEARCH_GLOBAL_SETTINGS = {
                    results: {
                        name: 'results',
                        id: 0,
                        urlTemplate: baseURL + '/short-list?text={value}&org={inputPhrase}',
                        onSuccess: _procesResults,
                        onFail: _handleError
                    },

                    history: {
                        name: 'history',
                        id: 1
                    },

                    suggestions: {
                        name: 'suggestions',
                        id: 2,
                        urlTemplate: baseURL + '/suggest?text={value}',
                        onSuccess: _procesSuggestion,
                        onFail: _handleError
                    },

                    products: {
                        name: 'products',
                        id: 3,
                        urlTemplate: baseURL + '/short-list/products?text={value}&page={page}',
                        onSuccess: _procesMoreResults,
                        onFail: _handleMoreError
                    },

                    producers: {
                        name: 'producers',
                        id: 4,
                        urlTemplate: baseURL + '/short-list/producers?text={value}&page={page}',
                        onSuccess: _procesMoreResults,
                        onFail: _handleMoreError
                    }
                };
            }

            this.objects.history = new Shop.SearchHistory({
                onHistoryItemClick: function(evt, $el) {
                    _handleHistoryItemClick(self, evt, $el);
                },
                onHistoryDestory: function() {
                    //TODO add showing start phrases when'll be implemented
                    self.objects.view.clearView();
                }
            });

            /*
             * Not implemented yet
             */
            if (SEARCH_SETTINGS.startPhrases) {
                this.objects.startPhrases = new Shop.SearchStartPhrases({
                    phrases: SEARCH_SETTINGS.startPhrases,
                    onPhrasesClick: function(evt, $el) {
                        _setInputValue(self, $el.text());
                        self.elements.$search.submit();
                    }
                });
            }

            this.objects.view = new Shop.SearchView({
                $el: this.elements.$search,
                history: this.objects.history,
                startPhrases: this.objects.startPhrases,
                searchInstance: this,
                mobileBtn: !!SEARCH_SETTINGS.SHOW_MOBILE_BTN,
                cutProductName: !!SEARCH_SETTINGS.SHORTEN_NAMES,
                showProductsScore: !!SEARCH_SETTINGS.SHOW_PRODUCTS_SCORE,
                showProductsAvailability: !!SEARCH_SETTINGS.SHOW_PRODUCTS_AVAILABILITY,
                dynamicResultLoading: SEARCH_SETTINGS.PRODUCT_AUTOCOMPLETE_BUTTON_VERSION === 2,
                onResultMoved: function($newResult) {
                    var $suggestItem = $newResult.find('.js__search-suggest-item');

                    if ($suggestItem.length) {
                        self.firstSuggestion = null;
                        _setInputValue(self, $suggestItem.text());
                    }
                }
            });

            this.objects.cache = new Shop.SearchCache();

            _cacheDOM(this);
            _bindEvents(this);

            searchQueryRegExp = /\/searchquery\/([^/]{1,})/;
            searchPhrase = searchQueryRegExp.exec(window.location.pathname);

            if (searchPhrase) {
                searchPhrase = searchPhrase[1].replace(/\+/g, ' ');

                _setInputValue(this, decodeURIComponent(_deserializeInputPhrase(searchPhrase)));
            }

            inputValue = this.elements.$input.prop('value');
            this.callbacks.onSearchContentScroll = this.parent.debounce(this.blurInput.bind(this), 200);
            this.settings.twoColumnResultsView = this.elements.$search.hasClass('search_two-columns-view');
            this.inputValue = inputValue.length ? inputValue : '';

            return this;
        },

        open: function() {
            this.objects.view.open();
            this.elements.$input.focus();

            return this;
        },

        close: function() {
            this.objects.view.close();

            if (this.elements.$searchContent.scrollBound) {
                this.elements.$searchContent.scrollBound = false;
                this.elements.$searchContent.off('scroll', this.callbacks.onSearchContentScroll);
                this.elements.$search.off('scroll', this.callbacks.onSearchContentScroll);
            }

            return this;
        },

        sendRequest: function() {
            _prepareRequestAndSend(this);

            return this;
        },

        blurInput: function() {
            if (document.activeElement === this.elements.$input.get(0)) {
                this.elements.$input.blur();
            }

            return this;
        },

        isInputValueSelected: function() {
            return this.elements.$input.selectionEnd > 0;
        },

        submitForm: function(value) {
            if (value && value.length) {
                _setInputValue(this, value);
            }

            this.elements.$search.submit();

            return this;
        },

        /**
         *
         * @param {*} settings - objects which contains information/state about current loaded results
         * @example
         * {
         *     type: {
         *          name: 'producers',
         *          id: 1
         *     },
         *     page: 2
         * }
         */
        loadMoreResults: function(settings) {
            var requestSettings;

            this.moreResultLoaded = true;
            this.objects.view.showLoader();

            requestSettings = _setRequestSettings(this, {
                globalSetting: settings.type,
                value: settings.xhrPhrase,
                page: settings.page
            });

            requestSettings.onMoreError = settings.onError;
            requestSettings.onMoreSuccess = settings.onSuccess;

            _makeRequest(this, requestSettings);

            return this;
        }
    };

    function _cacheDOM(searchInstance) {
        searchInstance.elements.$input = searchInstance.elements.$search.find('.search__input');
        searchInstance.elements.$submitBtn = searchInstance.elements.$search.find('.js__search-submit-btn');
        searchInstance.elements.$btnClear = searchInstance.elements.$search.find('.js__search-clear-btn');
        searchInstance.elements.$searchContent = searchInstance.elements.$search.find('.search__content');
    }

    function _submitForm(searchInstance, evt) {
        var inputValue = searchInstance.elements.$input.prop('value');
        var historyValueToSet = inputValue;
        var productUrl;

        //error if input empty
        if (!inputValue.length) {
            evt.preventDefault();

            if (searchInstance.objects.history.getItemsCount() > 0) {
                searchInstance.objects.view.render(searchInstance.objects.view.viewTypes.history);
                searchInstance.objects.view.render(
                    searchInstance.objects.view.viewTypes.message,
                    {
                        type: searchInstance.objects.view.messagesTypes.emptyInputHistory
                    },
                    {
                        preserveView: true
                    }
                );
            } else {
                searchInstance.objects.view.render(searchInstance.objects.view.viewTypes.message, {
                    type: searchInstance.objects.view.messagesTypes.emptyInput
                });
            }
        } else {
            /**
             * If user enter returned product code, and submit form, will be redirected on product site
             */
            productUrl = searchInstance.productKeyUrlMaps[inputValue];
            if (productUrl) {
                evt.preventDefault();
                window.location = productUrl;
            } else if (searchInstance.firstSuggestion) {
                /**
                 * Turn off, maybe will be back
                 */
                // historyValueToSet = searchInstance.firstSuggestion;
                // _setInputValue(searchInstance, searchInstance.firstSuggestion);
            }

            customerPrivacy.onFunctionalConsentGranted(() => {
                searchInstance.objects.history.save(historyValueToSet);
            });
        }
    }

    /**
     * Handle enter press on listItem, handled here not in BaseTemplateClass because search input must have focus
     * while navigating (google|amazon search work that way), so evt.target is always search__input not searchItem.
     * @param {Object} searchInstance
     * @param {Object} evt
     */
    function _onListItemKeyPress(searchInstance, evt) {
        var SEARCH_GLOBAL_SETTINGS = Shop.Search.SEARCH_GLOBAL_SETTINGS;
        var $activeResult;
        var $searchSection;
        var $resultLink;
        var sectionType;

        $activeResult = searchInstance.elements.$search.find('.search__list-item_active');

        if ($activeResult.length) {
            $searchSection = $activeResult.parents('.search__section');
            sectionType = $searchSection.data('searchSectionType');

            switch (sectionType) {
                case SEARCH_GLOBAL_SETTINGS.suggestions.id:
                    _handleSuggestClick(searchInstance, evt, $activeResult);
                    break;
                case SEARCH_GLOBAL_SETTINGS.history.id:
                    _handleHistoryItemClick(searchInstance, evt, $activeResult);
                    break;
                case SEARCH_GLOBAL_SETTINGS.products.id:
                case SEARCH_GLOBAL_SETTINGS.producers.id:
                    _handleResultClick(searchInstance, evt, $activeResult);
                    break;
            }

            $resultLink = $activeResult.find('.js__search-result-link').get(0);
            if ($resultLink) {
                evt.preventDefault();
                $resultLink.click();
            }
        }
    }

    function _handleKeyPress(searchInstance, evt) {
        switch (evt.key) {
            case 'ArrowUp':
            case 'Up':
                searchInstance.objects.view.moveResult('prev');
                break;

            case 'ArrowDown':
            case 'Down':
                searchInstance.objects.view.moveResult('next');
                break;

            case 'Enter':
                _onListItemKeyPress(searchInstance, evt);
                break;
            case 'Escape':
            case 'Esc':
                // prevent clearing search input on escape
                evt.preventDefault();
                searchInstance.close();
                break;
        }
    }

    function _bindEvents(searchInstance) {
        searchInstance.elements.$input.on({
            focus: function() {
                var sendRequest;

                searchInstance.objects.view.open();

                if (!searchInstance.elements.$search.hasClass('search_focused')) {
                    searchInstance.elements.$search.addClass('search_focused');
                }

                if (searchInstance.parent.rwd.xs && !searchInstance.elements.$searchContent.scrollBound) {
                    searchInstance.elements.$searchContent.scrollBound = true;
                    searchInstance.elements.$searchContent.on('scroll', searchInstance.callbacks.onSearchContentScroll);
                    searchInstance.elements.$search.off('scroll', searchInstance.callbacks.onSearchContentScroll);
                }

                /**
                 * if input phrese didnt change and appropriate time (set in search settings) didnt pass we done send a request
                 */
                sendRequest =
                    searchInstance.origPhrase === searchInstance.inputValue &&
                    Date.now() - searchInstance.inputValueSetTime < SEARCH_OPTIONS.cacheSameInputMarkup;

                _setInputValue(searchInstance, null, true, !sendRequest);
            },

            blur: function() {
                searchInstance.elements.$search.removeClass('search_focused');
            },

            input: searchInstance.parent.debounce(function() {
                searchInstance.origPhrase = this.value;
                searchInstance.inputValueSetTime = Date.now();

                _setInputValue(searchInstance, this.value, true, true);
            }, SEARCH_OPTIONS.delay),

            keydown: function(evt) {
                if (searchInstance.objects.view.isOpen()) {
                    _handleKeyPress(searchInstance, evt);
                }
            }
        });

        searchInstance.elements.$btnClear.on('click', function() {
            searchInstance.objects.view.open();

            _setInputValue(searchInstance, '', true);
            searchInstance.elements.$input.focus();
        });

        searchInstance.elements.$submitBtn.on('click', function() {
            searchInstance.objects.view.open();

            searchInstance.elements.$search.submit();
        });

        searchInstance.elements.$search.on('submit', function(evt) {
            _submitForm(searchInstance, evt);
        });
    }

    function _resetRequestsState(searchInstance) {
        if (searchInstance.requestsPending) {
            searchInstance.requests.forEach(function(request) {
                if (request && request.readyState !== 4) {
                    request.abort();
                }
            });
        }

        searchInstance.parent.forEachIn(searchInstance.attempts, function(value, key) {
            searchInstance.attempts[key] = 0;
        });

        searchInstance.requests = [];
        searchInstance.requestsPending = false;
        searchInstance.dataReturned = 0;
        searchInstance.moreResultLoaded = false;
        searchInstance.productKeyUrlMaps = {};
        searchInstance.firstSuggestion = null;

        searchInstance.objects.view.hideLoader();

        if (searchInstance.requestTimeout) {
            clearTimeout(searchInstance.requestTimeout);
        }
    }

    function _prepareRequestAndSend(searchInstance) {
        var SEARCH_GLOBAL_SETTINGS = Shop.Search.SEARCH_GLOBAL_SETTINGS;
        var cachedValue;
        var requestType = null;
        var regSubstractQuery = /[^\s]{1,}?(\s|^)-\w+/;

        _resetRequestsState(searchInstance);

        if (searchInstance.inputValue && searchInstance.inputValue.length >= 1) {
            searchInstance.objects.view.clearView(true);

            requestType =
                !!SEARCH_SETTINGS.SHOW_PHRASE_SUGGESTIONS && !regSubstractQuery.test(searchInstance.inputValue)
                    ? SEARCH_GLOBAL_SETTINGS.suggestions
                    : SEARCH_GLOBAL_SETTINGS.results;

            if (requestType.id === SEARCH_GLOBAL_SETTINGS.results.id && XHR_ALL_RESULTS_DISABLED) {
                requestType = null;
            }

            if (requestType !== null) {
                searchInstance.objects.view.showLoader();
                cachedValue = searchInstance.objects.cache.get(searchInstance.inputValue, requestType.name);

                if (cachedValue && cachedValue.response) {
                    _procesCachedValue(searchInstance, requestType.id, cachedValue);
                } else {
                    _makeRequest(
                        searchInstance,
                        _setRequestSettings(searchInstance, {
                            globalSetting: requestType,
                            value: searchInstance.inputValue
                        })
                    );
                }
            } else {
                searchInstance.objects.view.render(searchInstance.objects.view.viewTypes.message, {
                    type: searchInstance.objects.view.messagesTypes.noResult,
                    inputValue: searchInstance.inputValue
                });
            }
        }
    }

    function _procesCachedValue(searchInstance, type, value) {
        if (type === Shop.Search.SEARCH_GLOBAL_SETTINGS.suggestions.id) {
            _procesSuggestion(searchInstance, value.response);
        } else {
            _procesResults(searchInstance, value.response);
        }
    }

    function _procesMoreResults(searchInstance, response, options, additionalSettings) {
        searchInstance.objects.view.hideLoader();

        if (response.options.name === Shop.Search.SEARCH_GLOBAL_SETTINGS.products.name && response.data.list.length) {
            response.data.list.forEach(function(product) {
                if (product.product_code && product.product_code.length) {
                    searchInstance.productKeyUrlMaps[product.product_code] = product.url;
                }
            });
        }

        searchInstance.objects.view.renderMore(response.options.id, response);
    }

    function _handleMoreError(searchInstance, xhr, options, additionalSettings) {
        if (searchInstance.attempts[options.name] < SEARCH_OPTIONS.maxRepeat && xhr.status !== 500) {
            searchInstance.requests = [];
            _repeatRequest(searchInstance, options, additionalSettings);
        } else {
            searchInstance.objects.view.hideLoader();

            if (typeof options.onMoreError === 'function') {
                options.onMoreError(searchInstance, xhr);
            }
        }
    }

    function _handleError(searchInstance, xhr, options, additionalSettings) {
        if (searchInstance.attempts[options.name] >= SEARCH_OPTIONS.maxRepeat || xhr.status === 500) {
            if (!searchInstance.dataReturned) {
                searchInstance.objects.view.render(searchInstance.objects.view.viewTypes.message, {
                    type: searchInstance.objects.view.messagesTypes.searchError,
                    inputValue: searchInstance.inputValue
                });
            }

            searchInstance.objects.view.hideLoader();
        } else {
            searchInstance.requests = [];
            _repeatRequest(searchInstance, options, additionalSettings);
        }
    }

    function _repeatRequest(searchInstance, options, additionalSettings) {
        searchInstance.requestTimeout = setTimeout(function() {
            _makeRequest(searchInstance, options, additionalSettings);
        }, SEARCH_OPTIONS.requestRepeatDelay * options.attempt);
    }

    function _procesSuggestion(searchInstance, response, additionalSettings) {
        var cachedValue;

        if (response.data && response.data.length) {
            searchInstance.firstSuggestion = response.data[0];
            searchInstance.dataReturned += 1;
        }

        /**
         * always render suggestion event if results not returned, because hxr 2 can return data which
         * are list in suggestion section
         */
        searchInstance.objects.view.render(
            searchInstance.objects.view.viewTypes.suggestions,
            {
                response: response,
                onListItemClick: function(evt, $el) {
                    _handleSuggestClick(searchInstance, evt, $el);
                },
                hide: response.data.length ? false : true,
                twoColumnResultsView: searchInstance.settings.twoColumnResultsView
            },
            additionalSettings
        );

        if (!XHR_ALL_RESULTS_DISABLED) {
            cachedValue = searchInstance.objects.cache.get(searchInstance.inputValue, Shop.Search.SEARCH_GLOBAL_SETTINGS.results.name);

            if (cachedValue && cachedValue.response) {
                _procesResults(searchInstance, cachedValue.response, {
                    preserveView: true,
                    firstSuggestion: searchInstance.firstSuggestion || ''
                });
            } else {
                _makeRequest(
                    searchInstance,
                    _setRequestSettings(searchInstance, {
                        globalSetting: Shop.Search.SEARCH_GLOBAL_SETTINGS.results,
                        value: response.data.length ? response.data[0] : searchInstance.inputValue
                    }),
                    {
                        preserveView: true,
                        firstSuggestion: response.data[0] || ''
                    }
                );
            }
        } else {
            searchInstance.objects.view.hideLoader();

            if (response.data && !response.data.length) {
                searchInstance.objects.view.render(searchInstance.objects.view.viewTypes.message, {
                    type: searchInstance.objects.view.messagesTypes.noResult,
                    inputValue: searchInstance.inputValue
                });
            }
        }
    }

    /**
     * set specific request settings and return it
     * @param {Object} searchInstance
     * @param {Object} settings
     * @returns {Object} settings for specific request type
     */
    function _setRequestSettings(searchInstance, settings) {
        var substituteObject = {
            value: encodeURIComponent(settings.value)
        };

        if (settings.page) {
            substituteObject.page = settings.page;
        }

        if (settings.globalSetting.id === Shop.Search.SEARCH_GLOBAL_SETTINGS.results.id) {
            substituteObject.inputPhrase = encodeURIComponent(searchInstance.inputValue);
        }

        return $.extend(
            {
                url: searchInstance.parent.substitute(settings.globalSetting.urlTemplate, substituteObject),
                xhrPhrase: substituteObject.value
            },
            settings.globalSetting
        );
    }

    function _deserializeInputPhrase(value) {
        if (value.indexOf('%2f') !== -1) {
            value = value.replace(/%2f/g, '/');
        }

        //for double encoding in case when user plase # in query string
        if (value.indexOf('%2523') !== -1) {
            value = value.replace(/%2523/g, '#');
        }

        //for double encoding in case when user plase / in query string
        if (value.indexOf('%252f') !== -1) {
            value = value.replace(/%252f/g, '/');
        }

        if (value.indexOf('%23') !== -1) {
            value = value.replace(/%23/g, '#');
        }

        return value;
    }

    function _setInputValue(searchInstance, forceValue, updateView, sendRequest) {
        var valueToSet = forceValue !== null ? forceValue : searchInstance.elements.$input.prop('value');

        if (forceValue !== null) {
            searchInstance.elements.$input.prop('value', valueToSet);
        }

        searchInstance.inputValue = valueToSet;

        if (valueToSet.length >= 1) {
            searchInstance.objects.view.showElement(searchInstance.elements.$btnClear);
        } else {
            searchInstance.objects.view.hideElement(searchInstance.elements.$btnClear);
        }

        if (updateView && !searchInstance.inputValue) {
            /**
             * Waiting for imlementing start phrases
             * renderType = searchInstance.objects.history.getItemsCount() > 0 ? searchInstance.objects.view.viewTypes.history : searchInstance.objects.view.viewTypes.startPhrases;
             * searchInstance.objects.view.render(renderType);
             */
            if (searchInstance.objects.history.getItemsCount()) {
                searchInstance.objects.view.render(searchInstance.objects.view.viewTypes.history);
            } else {
                searchInstance.objects.view.clearView(false, true);
            }
        }

        if (sendRequest) {
            _prepareRequestAndSend(searchInstance);
        }
    }

    function _handleSuggestClick(searchInstance, evt, $el) {
        var text;
        var $link;

        if ($(evt.target).hasClass('js__search-btn-copy')) {
            evt.preventDefault();

            text = $el.find('.js__search-suggest-item').text() + ' ';
            _setInputValue(searchInstance, text, true, true);

            searchInstance.elements.$input.focus();

            return;
        }

        $link = $el.find('.js__search-result-link');
        searchInstance.firstSuggestion = null;
        if (!$link.length) {
            text = $el.find('.js__search-suggest-item').text();

            _setInputValue(searchInstance, text);

            customerPrivacy.onFunctionalConsentGranted(() => {
                searchInstance.objects.history.save(text);
            });
            searchInstance.elements.$search.submit();
        }
    }

    function _handleHistoryItemClick(searchInstance, evt, $el) {
        _setInputValue(searchInstance, $el.text());
        searchInstance.elements.$search.submit();
    }

    function _handleResultClick(searchInstance) {
        customerPrivacy.onFunctionalConsentGranted(() => {
            searchInstance.objects.history.save(searchInstance.origPhrase);
        });
    }

    function _procesResults(searchInstance, response, additionalSettings) {
        var results = false;

        additionalSettings = additionalSettings || {};

        searchInstance.parent.forEachIn(response.data, function(value, key) {
            if (value.count > 0) {
                results = true;
            }

            if (key === 'products' && value.list.length) {
                value.list.forEach(function(product) {
                    if (product.product_code && product.product_code.length)
                        searchInstance.productKeyUrlMaps[product.product_code] = product.url;
                });
            }
        });

        searchInstance.objects.view.hideLoader();
        if (results) {
            searchInstance.objects.view.render(
                searchInstance.objects.view.viewTypes.results,
                {
                    response: response,
                    onListItemClick: function(evt, $el) {
                        _handleResultClick(searchInstance, evt, $el);
                    },

                    firstSuggestion: additionalSettings.firstSuggestion,
                    twoColumnResultsView: searchInstance.settings.twoColumnResultsView
                },
                additionalSettings
            );
        } else if (!$('[data-search-section-type="' + Shop.Search.SEARCH_GLOBAL_SETTINGS.suggestions.id + '"] .search__list-item').length) {
            searchInstance.objects.view.render(searchInstance.objects.view.viewTypes.message, {
                type: searchInstance.objects.view.messagesTypes.noResult,
                inputValue: searchInstance.inputValue
            });
        }
    }

    function _makeRequest(searchInstance, options, additionalSettings) {
        var responseObject;
        var xhrRequest;

        searchInstance.attempts[options.name] += 1;
        searchInstance.requestsPending = true;
        xhrRequest = $.ajax({
            url: options.url,
            dataType: 'json'
        })
            .done(function(response, status, xhr) {
                searchInstance.requestsPending = false;

                if (status === 'success') {
                    if (response.error) {
                        if (typeof options.onFail === 'function') {
                            options.onFail(
                                searchInstance,
                                xhr,
                                options,
                                $.extend(additionalSettings, {
                                    xhrPhrase: options.xhrPhrase
                                })
                            );
                        }
                        return;
                    }

                    searchInstance.parent.escapeJSONData(response, function(data, value, key) {
                        if (key !== 'url') {
                            data[key] = searchInstance.parent.simpleSanitizeHTML(value);
                        }
                    });

                    responseObject = {
                        data: response,
                        options: $.extend({}, options)
                    };

                    responseObject.options.onFail = null;
                    responseObject.options.onSuccess = null;

                    searchInstance.objects.cache.set(searchInstance.inputValue, options.name, responseObject);
                    if (typeof options.onSuccess === 'function') {
                        options.onSuccess(searchInstance, responseObject, additionalSettings);
                    }
                }
            })
            .fail(function(xhr) {
                searchInstance.requestsPending = false;
                if (typeof options.onFail === 'function') {
                    options.onFail(searchInstance, xhr, options, additionalSettings);
                }
            });

        searchInstance.requests.push(xhrRequest);
    }
})();
