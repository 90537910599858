;(function (undefined) {
	Shop.QuickView.include({
        options: {
            requestUrl: 'product/getproduct/id/',

            selectors: {
                button: '.quickview',
                stockId: 'stock_id'
            },

            elements: {
                button: null,
                modal: null
            },

            events: {
                request: null
            },

            cache: {},
            nettoPrice: false,

            callbacks: {
                onPreShow: null,
                onPostShow: null
            }
        },

        initialize: function(options) {
            this.constructor.setOptions(options);

            var shopClass = this.parent,
                options = this.options;

            options.elements.modal = new Shop.Modal();
            options.elements.button = $(options.selectors.button);
            options.nettoPrice = $('body').find('[data-netto]').length ? true : false;
            
            options.events.request = function (e) {
                this.request(e);
            }.bind(this);

            options.elements.button.on('mousedown', options.events.request);
        },

        request: function(e) {
            var options = this.options,
                button = ($(e.target).prop("tagName") !== 'A') ? $(e.target).parent() : $(e.target),
                productId = parseInt(button.data('id'), 10),
                req;

            if (options.cache[productId] === undefined) {
                req = $.ajax({
                    url: this.parent.url(options.requestUrl + productId),
                    type: "get"
                });

                req.done(function (data) {
                    this.create(data, button);
                }.bind(this));

                req.fail(function () {
                    this.parent.alert('ERROR!');
                }.bind(this));
            } else {
                this.show(productId);
            }
        },

        create: function (data, $button) {
            var options = this.options;
            var $mainDiv;
            var $gallery;
            var $link;
            var $product;
            var productHtml;
            var price = '';
            var priceType;
            var priceS;
            var stars;
            var promo = '';
            var producer = '';
            var basket = '';
            var evaluation = '';
            var delivery = '';
            var starI;
            var unitCalculation = '';
            var isLoyalty;
            var $form;
            var action;
            var method;

            options.cache[data.id] = {};
            options.cache[data.id]['content'] = document.createDocumentFragment();
            options.cache[data.id]['title'] = data.name;
   
            $mainDiv = $('<div/>').addClass('product-innerdesc-wrap');
            $gallery = $('<div/>').addClass('gallery f-row').appendTo($mainDiv);
            $link = $('<a/>').appendTo($gallery).attr('href', data.url);
            $product = $('<div/>').addClass('product-info').appendTo($mainDiv);
            $button.parents('.product').find('.img-wrap img').clone(true).appendTo($link);
            $form = $button.prev();

            stars = '';
            for (starI = 0; starI < 5; starI++) {
                stars += '<img src="' + this.parent.url('libraries/images/1px.gif')  + '" class="px1 ' + ((data.rate > starI) ? 'star1': 'star0') +'" />'
            }

            priceType = options.nettoPrice ? 'net' : 'gross';

            priceS = '';

            if (data.price && data.price[priceType].currency_base_float) {
                if (!data.price[priceType].promo && data.price[priceType].currency_base !== data.price[priceType].final) {
                    priceS = '<em class="default-currency">(' + data.price[priceType].currency_base + ')</em>';
                } else if (data.price[priceType].promo && data.price[priceType].currency_promo !== data.price[priceType].final) {
                    priceS = '<em class="default-currency">(' + data.price[priceType].currency_promo + ')</em>';
                }
            }

            if ($button.data('price')) {
                price = '<div class="price">' + Shop.lang.quickview.price + ': ' + 
                            '<em>' + (data.price[priceType].promo ? data.price[priceType].promo : data.price[priceType].base) + '</em>' + 
                            (data.price[priceType].promo ? '<del>' + data.price[priceType].base + '</del> ' : '') +
                            priceS +
                        '</div>';
            }
            
            if (data.price && (data.price[priceType].promo || (data.newProduct == true))) {
                promo = $('<ul/>').addClass('tags').html('<ul class="tags">' + 
                        (data.price[priceType].promo ? '<li class="promo"><span>' + Shop.lang.common.discount + '</span></li>' : '') +
                        (data.newProduct == true ? '<li class="new"><span>' + Shop.lang.common.newP +'</span></li>' : '') +
                    '</ul>');
                promo.appendTo($gallery);
            }

            if (data.producer) {
                producer = '' +
                    '<div class="row manufacturer">' +
                        '<em>' + Shop.lang.quickview.producer + ': </em>' +
                        '<a class="brand" href="' + data.producer.url + '" title="' + Shop.fn.simpleSanitizeHTML(data.producer.name) + '">' + Shop.fn.simpleSanitizeHTML(data.producer.name) + '</a>' +
                    '</div>';
            }

            if ($form.is('.basket') && !!$form.find('.addtobasket').length) {
                isLoyalty = $form.hasClass('loyaltyexchange');

                if (isLoyalty) {
                    action = $form.attr('action');
                    method = 'post';
                } else if (data.basket) {
                    action = data.basket.replace('post', data.stockId);
                    method = 'get';
                }

                basket = '' +
                    '<form class="basket" action="' + action + '" method="' + method + '">' +
                        '<fieldset>' + 
                            '<button class="addtobasket btn btn-red" type="submit">' + 
                                '<img src="' + this.parent.url('libraries/images/1px.gif')  + '" class="px1">' +
                                '<span>' + (isLoyalty ? Shop.lang.quickview.exchange : Shop.lang.quickview.addtobasket) + '</span>' + 
                            '</button>' +
                        '</fieldset>' + (isLoyalty ? '<input type="hidden" value="' + data.stockId + '" name="stock_id"><input name="quantity" value="1" type="hidden">' : '') +
                    '</form>';
            }

            if ($form.is('.availability-notifier')) {
                basket = '<form class="availability-notifier">' +
                        '<fieldset>' + 
                            '<button class="availability-notifier-btn quickview-notifier btn btn-red" type="submit">' + 
                                '<img src="' + this.parent.url('libraries/images/1px.gif')  + '" class="px1">' +
                                '<span>' + Shop.lang.product.availability_notify_title + '</span>' + 
                            '</button>' +
                        '</fieldset>' +
                    '</form>';
            }

            if ($button.data('eval') === 1) {
                evaluation = '' + 
                    '<div class="row evaluation">' +
                        '<em>' + Shop.lang.quickview.evaluation + ': </em>' + 
                        '<span class="votestars">' + stars + '</span>' +
                    '</div>';
            }

            if (data.delivery) {
                delivery = '' + 
                    '<div class="delivery">' + 
                        '<span class="first">' + Shop.lang.quickview.delivery + ': </span>' + 
                        '<span class="second">' + Shop.fn.simpleSanitizeHTML(data.delivery) + '</span>' +
                    '</div>';
            }

            if (data.unit_calculation) {
                unitCalculation = '' +
                    '<div class="unit-calculation">' + 
                        '<span class="default-currency">( 1 ' + data.unit_calculation.unit + ' = ' + data.unit_calculation.price.gross.base + ' )</span>' +
                    '</div>';
            }

            productHtml = '' +
                '<div class="availability row">' +
                    '<div class="row">' +
                        '<div class="row availability">' + 
                            '<span class="first">' + Shop.lang.quickview.availability + ': </span>' + 
                            '<span class="second">' + Shop.fn.simpleSanitizeHTML(data.availability.name) + '</span>' +
                        '</div>' + 
                        delivery +
                    '</div>' +
                '</div>' + 
                '<div class="description f-row resetcss">' + data.short_description + '</div>' + 
                '<div class="productdetails-more row">' +
                    evaluation +
                    producer +
                    price +
                    unitCalculation + 
                    basket +
                '</div>';

            $product.html(productHtml);
            $mainDiv.appendTo(options.cache[data.id]['content']);
            this.show(data.id, $button);
        },

        show: function (id, $button) {
            var options = this.options,
                modal = options.elements.modal;

            if (typeof options.callbacks.onPreShow === 'function') {
                options.callbacks.onPreShow.call(this, arguments);
            }

            modal.options.header = Shop.fn.simpleSanitizeHTML(options.cache[id]['title']);
            modal.options.content = options.cache[id]['content'].cloneNode(true);
            modal.options.footer = null;

            if (modal._isCreated) {
                modal.loadContent();
                modal.reposition();
                modal._createMask();
            } else {
                modal.createModal();
                modal.reposition();
            }

            modal.object.body.find('.quickview-notifier').on('click', function (ev) {
                ev.preventDefault();
                $button.prev().find('.availability-notifier-btn').trigger('click');
            });

            if (typeof options.callbacks.onPostShow === 'function') {
                options.callbacks.onPostShow.call(this, arguments);
            }

            modal.reposition();
        }
    });
})();