import { doesBasketExist, updateBasket } from './helpers';
import { store } from '@frontstoreRwd/store';
import { IApiBasketInformation } from '@frontstoreApi/basket/types';

export default {
    $boxFreeShipping: null as HTMLElement | null,

    freeShippingBoxInitialize() {
        this.$boxFreeShipping = document.querySelector('#box_freeshipping .free-shipping__content');
    },

    getFreeShippingText(basketInfo: IApiBasketInformation): string {
        const basketSumFloat: number = basketInfo.basket.sum_no_shipping_float;
        const freeShippingTitle: string = basketInfo.free_shipping!.title;
        const freeShippingAmount: string = basketInfo.free_shipping!.free_shipping;
        const freeShippingAmountFloat: number = basketInfo.free_shipping!.free_shipping_float;
        const freeShippingMissingAmount: string = basketInfo.free_shipping!.missing;

        if (basketSumFloat === 0) {
            //@ts-ignore
            return window.Shop.lang.ajaxbasket.free_shipping_empty
                .replace('{shippingName}', freeShippingTitle)
                .replace('{shippingAmount}', freeShippingAmount);
        } else {
            if (basketSumFloat > freeShippingAmountFloat) {
                //@ts-ignore
                return window.Shop.lang.ajaxbasket.free_shipping_higher
                    .replace('{shippingName}', freeShippingTitle)
                    .replace('{shippingAmount}', freeShippingAmount);
            } else if (basketSumFloat === freeShippingAmountFloat) {
                //@ts-ignore
                return window.Shop.lang.ajaxbasket.free_shipping_equal
                    .replace('{shippingName}', freeShippingTitle)
                    .replace('{shippingAmount}', freeShippingAmount);
            }

            //@ts-ignore
            return window.Shop.lang.ajaxbasket.free_shipping_lower
                .replace('{shippingAmount}', freeShippingMissingAmount)
                .replace('{shippingName}', freeShippingTitle);
        }
    },

    async updateFreeShippingBoxView(): Promise<boolean> {
        const isBoxShippingUpdateable: boolean = doesBasketExist() && Boolean(this.$boxFreeShipping);

        if (isBoxShippingUpdateable === true) {
            if (store.basketInfo && !store.basketInfo.free_shipping) {
                await updateBasket();
            }
            this.$boxFreeShipping!.textContent = String(this.getFreeShippingText(store.basketInfo!));

            return true;
        }

        return false;
    }
};
