import { IBasketVatEU } from '@frontstoreRwd/modules/basket_vat_eu/types';
import { COMPANY_ID, POLAND_COUNTRY_ID, SELECTOR_VAT_EU_CHECKBOX } from './constants';

/**
 * This module contains 4 sections:
 * Logic, for logic functions, when something should be hidden/visible/disabled/available
 * View, to manage the view base on Logic functions
 * Listeners, functions which is adding event listeners
 * Initialize, initialize function
 */

export const basketVatEU = {
    options: {
        euCountries: [],
        countryToShipping: [],
        countryCode: 0
    },

    /** LOGIC **/

    isCompanyView() {
        const $checkedRadio = document.querySelector('[name="address_type"]:checked') as HTMLInputElement;

        return Number($checkedRadio.value) === COMPANY_ID;
    },
    isEUCountry(value: string) {
        const { euCountries } = this.options;

        return euCountries?.indexOf(Number(value)) !== -1;
    },
    isShipmentAvailable(value: string) {
        const { countryToShipping } = this.options;

        return countryToShipping.indexOf(Number(value)) !== -1;
    },
    isDifferentCountry(value: string) {
        return this.options.countryCode !== Number(value);
    },
    isCompanyNipObligatory(){
        const $select = document.querySelector('#input_country') as HTMLSelectElement;
        const { value } = $select.selectedOptions[0];
        const { checked } = document.querySelector(SELECTOR_VAT_EU_CHECKBOX) as HTMLInputElement;

        return Number(value) === POLAND_COUNTRY_ID || checked;
    },
    isVATEUAvailable() {
        const $select = document.querySelector('#input_country') as HTMLSelectElement;
        const { value } = $select.selectedOptions[0];
        const isDifferentCountry = this.isDifferentCountry(value);
        const isShipmentAvailable = this.isShipmentAvailable(value);
        const isEUCountry = this.isEUCountry(value);
        const isCompanyView = this.isCompanyView();

        return isDifferentCountry && isCompanyView && isEUCountry && isShipmentAvailable;
    },

    /** VIEW **/

    toggleDeliveryCheckbox() {
        const { checked: isDisabled } = document.querySelector(SELECTOR_VAT_EU_CHECKBOX) as HTMLInputElement;
        const deliveryAddressCheckbox = document.querySelector('#address_different') as HTMLInputElement;
        if (!deliveryAddressCheckbox) {
            return;
        }

        deliveryAddressCheckbox.disabled = isDisabled;
    },
    toggleVatUE() {
        const $container = document.querySelector('[class*="vat_eu"]');
        const $vatCheckbox = document.querySelector(SELECTOR_VAT_EU_CHECKBOX) as HTMLInputElement;
        const $deliveryAddressCheckbox = document.querySelector('#address_different') as HTMLInputElement;

        if (!$container) {
            return;
        }

        if (this.isVATEUAvailable()) {
            $container.classList.remove('none');
            $vatCheckbox.disabled = $deliveryAddressCheckbox.checked;
        } else {
            $container.classList.add('none');
            $vatCheckbox.checked = false;
        }
    },

    /** LISTENERS **/

    addDeliveryCheckboxListener() {
        const $deliveryAddressCheckbox = document.querySelector('#address_different');
        if (!$deliveryAddressCheckbox) {
            return;
        }
        $deliveryAddressCheckbox.addEventListener('change', () => {
            this.updateView();
        });
    },
    addVatCheckboxListener() {
        const $vatCheckbox = document.querySelector(SELECTOR_VAT_EU_CHECKBOX);
        if ($vatCheckbox) {
            $vatCheckbox.addEventListener('change', () => {
                this.updateView();
            });
        }
    },
    addRadioListeners() {
        const radios = document.querySelectorAll('[name="address_type"]');

        if (radios) {
            radios.forEach((radio) => {
                radio.addEventListener('click', () => {
                    this.updateView();
                });
            });
        }
    },
    addSelectListener() {
        const $select = document.querySelector('#input_country');
        if (!$select) {
            return;
        }
        $select.addEventListener('change', () => {
            this.updateView();
        });
    },

    updateView() {
        this.toggleVatUE();
        this.toggleDeliveryCheckbox();
        this.toggleObligatoryFields();
    },

    toggleObligatoryFields(){
        ['input_coname', 'input_nip'].forEach(id=>{
            if(this.isCompanyNipObligatory()){
                if(document.querySelector(`[for="${id}"]>em`)){
                    return;
                }
                const $obligatoryMark = document.createElement('em');
                $obligatoryMark.setAttribute('class','color');
                $obligatoryMark.innerText = "*";
                document.querySelector(`[for="${id}"]`)?.prepend($obligatoryMark);
            } else {
                [...document.querySelectorAll(`[for="${id}"]>em`)].forEach(element=>element.remove());
            }
        })
    },

    /** INITIALIZE **/

    async initialize() {
        // @ts-ignore
        this.options.euCountries = Shop.values.euCountries;
        // @ts-ignore
        this.options.countryToShipping = Shop.values.Country2Shipping;
        // @ts-ignore
        this.options.countryCode = Shop.values.countryCode;
        this.addSelectListener();
        this.addVatCheckboxListener();
        this.addDeliveryCheckboxListener();
        this.addRadioListeners();
        this.updateView();
    }
} as IBasketVatEU;
