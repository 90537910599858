(function(undefined) {
    /**
     * Render pickup points, provided by API
     * @param {number} maxMapWidth
     * @param {number} pointsListMinWidth
     */
    Shop.PocztaPolska.include({
        options: {
            maxMapWidth: 500,
            pointsListMinWidth: 200,
            googleMapsApikey: null
        },

        elements: {
            modal: null,
            modalsWidth: null,
            $container: null,
            $map: null,
            $mapPointsList: null,
            $mapContainer: null,
            $pickUpsListContainer: null
        },

        /**
         * map marker icons
         */
        icons: {
            POCZTA: null,
            ORLEN: null,
            AUTOMAT_POCZTOWY: null,
            RUCH: null,
            ZABKA: null,
            FRESHMARKET: null
        },

        classes: {
            ppOutpostItemActive: 'outpost_active',
            ppOutpostOnlyPrepayment: 'outpost__only-prepayment'
        },

        /**
         * instance of MapPoint class
         */
        objects: {
            mapPoints: null,
            gmap: null
        },

        callbacks: {
            close: null,
            selected: null
        },

        pointTypes: [
            'POCZTA',
            'ORLEN',
            'AUTOMAT_POCZTOWY',
            'RUCH',
            'ZABKA',
            'FRESHMARKET',
            'AUTOMAT_BIEDRONKA',
            'AUTOMAT_CARREFOUR',
            'AUTOMAT_SPOLEM',
            'AUTOMAT_LEWIATAN',
            'AUTOMAT_PLACOWKA'
        ],
        renderedPoints: {},
        allPointsLoaded: false,

        isGoogleMapsInit: false,

        currentPoint: null,

        /**
         * @param {object} options
         */
        initialize: function(options) {
            this.constructor.setOptions(options);
        },

        /**
         * create instance of GoogleMapsAPI. Bind event if api is read to show map. Set appropriate icons for points
         */
        initGoogleMaps: function(onlyPrepayment) {
            var self = this;

            this.initModal();
            this.isGoogleMapsInit = true;

            this.icons.POCZTA = this.parent.url('libraries/images/poczta.svg');
            this.icons.ORLEN = this.parent.url('libraries/images/orlen.svg');
            this.icons.RUCH = this.parent.url('libraries/images/ruch.svg');
            this.icons.ZABKA = this.parent.url('libraries/images/zabka.svg');
            this.icons.AUTOMAT_POCZTOWY = this.parent.url('libraries/images/poczta-automat-pocztowy-placowka.svg');
            this.icons.FRESHMARKET = this.parent.url('libraries/images/freshmarket.svg');
            this.icons.AUTOMAT_BIEDRONKA = this.parent.url('libraries/images/biedronka.svg');
            this.icons.AUTOMAT_CARREFOUR = this.parent.url('libraries/images/carrefour.svg');
            this.icons.AUTOMAT_SPOLEM = this.parent.url('libraries/images/spolem.svg');
            this.icons.AUTOMAT_LEWIATAN = this.parent.url('libraries/images/lewiatan.svg');
            this.icons.AUTOMAT_PLACOWKA = this.parent.url('libraries/images/poczta-automat-pocztowy-placowka.svg');

            Shop.googleMapsApi = new Shop.GoogleMapsAPI({
                $loaderContainer: this.elements.modal.object.body,
                googleMapsApikey: this.options.googleMapsApikey
            });

            if (Shop.googleMapsApi.isApiReady) {
                this.show(onlyPrepayment);
            } else {
                this.parent.addEvent('GoogleMapsAPI:ready', function() {
                    self.show(onlyPrepayment);
                });
            }
        },

        /**
         * create new Modal, insert appropriate markup
         */
        initModal: function() {
            var self = this;
            var $searchContainer;

            this.elements.modal = new Shop.Modal({
                header: Shop.lang.pickupsPoints.find_point
            });

            this.elements.modal.callbacks.close = function() {
                if (typeof self.callbacks.close === 'function') {
                    self.callbacks.close(self);
                }
            };

            this.elements.modal.callbacks.onReposition = function() {
                var offsetTop;

                if (!!$('.pp-pick-up-points__modal').length) {
                    offsetTop = $('.pp-pick-up-points__modal').offset().top;

                    if (offsetTop < $(window).scrollTop()) {
                        window.scroll(0, offsetTop + 10);
                    }
                }
            };

            this.elements.$container = $('<div />', {
                class: 'pp-pick-up-points'
            });

            this.elements.$mapContainer = $('<div />', {
                class: 'pp-pick-up-points__map-container'
            }).appendTo(this.elements.$container);

            $searchContainer = $('<div />', {
                class: 'pp-pick-up-points__search-container'
            }).appendTo(this.elements.$mapContainer);

            $searchContainer.append(
                $('<input />', {
                    type: 'search',
                    id: 'pp-pick-up-search',
                    class: 'pp-pick-up-points__search',
                    placeholder: Shop.lang.pickupsPoints.enter_address
                })
            );

            this.elements.$map = $('<div />', {
                id: 'pp-pick-up-map',
                class: 'pp-pick-up-points__map'
            }).appendTo(this.elements.$mapContainer);

            this.elements.$pickUpsListContainer = $('<div />', {
                class: 'pp-pick-up-points__outposts-container'
            }).appendTo(this.elements.$container);

            $('<h5 />', {
                class: 'pp-pick-up-points__header',
                text: Shop.lang.pickupsPoints.select_pickup_point_list + ':'
            }).appendTo(this.elements.$pickUpsListContainer);

            this.elements.$mapPointsList = $('<ul />', {
                id: 'pp-pick-up-outposts',
                class: 'pp-pick-up-points__outposts'
            }).appendTo(this.elements.$pickUpsListContainer);

            this.elements.modal.options.content = this.elements.$container;
            this.elements.modal.createModal();
            this.elements.modal.object.container.addClass('pp-pick-up-points__modal');
        },

        updatePlaceView: function(location) {
            var self = this;
            var gmap = this.objects.gmap;

            this.updateMapAndView(gmap, location, this.options.searchZoomLevel);

            this.getPointsByLatLang(gmap.getCenter().lat(), gmap.getCenter().lng())
                .then(function(pickUpPoints, statusText) {
                    if (!!pickUpPoints.length && statusText === 'success') {
                        self.renderPoints(gmap, pickUpPoints);
                        self.renderRelativeParcelsList(gmap, pickUpPoints);
                    }
                })
                .fail(function() {
                    self.handleApiError();
                });
        },

        /**
         * show modal, create MapPoints object if it's not created
         */
        show: function(onlyPrepayment) {
            if (!this.isGoogleMapsInit) {
                this.initGoogleMaps(onlyPrepayment);
                return;
            }

            this.elements.modal.createModal();

            if (!this.objects.mapPoints) {
                this.elements.modalsWidth = this.elements.modal.params.width;
                this.objects.mapPoints = new Shop.MapPoints({
                    $map: this.elements.$map,
                    $searchContainer: $('.pp-pick-up-points__search-container'),
                    $search: $('#pp-pick-up-search'),
                    $mapPointsList: this.elements.$mapPointsList,
                    autocomplete: {
                        settings: {
                            country: ['pl']
                        },
                        position: 'TOP_CENTER'
                    },
                    callbacks: {
                        updateView: function(caller, centerLoc) {
                            this.getPointsByLatLang(self.objects.gmap.getCenter().lat(), self.objects.gmap.getCenter().lng())
                                .then(function(pickUpPoints, statusText) {
                                    if (!!pickUpPoints.length && statusText === 'success') {
                                        self.updateMapAndView(self.objects.gmap, centerLoc);
                                        self.renderPoints(self.objects.gmap, pickUpPoints);
                                        self.renderRelativeParcelsList(self.objects.gmap, pickUpPoints);
                                        self.parent.fireEvent('PickUpPoints:view-fully-updated');
                                    }
                                })
                                .fail(function() {
                                    self.handleApiError();
                                });
                        }.bind(this),

                        mapReady: function(caller) {
                            var self = this;
                            var gmap = caller.gmap;

                            this.objects.gmap = gmap;

                            this.getPointsByLatLang(gmap.getCenter().lat(), gmap.getCenter().lng())
                                .then(function(pickUpPoints, statusText) {
                                    if (!!pickUpPoints.length && statusText === 'success') {
                                        self.renderPoints(gmap, pickUpPoints);
                                        self.renderRelativeParcelsList(gmap, pickUpPoints);
                                        self.parent.fireEvent('PickUpPoints:view-fully-updated');
                                    }
                                })
                                .fail(function() {
                                    self.handleApiError();
                                });
                        }.bind(this),

                        mapZoomChange: function(caller) {
                            var self = this;
                            var gmap = caller.gmap;

                            this.getPointsByLatLang(gmap.getCenter().lat(), gmap.getCenter().lng())
                                .then(function(pickUpPoints, statusText) {
                                    if (!!pickUpPoints.length && statusText === 'success') {
                                        self.renderPoints(gmap, pickUpPoints);
                                    }
                                })
                                .fail(function() {
                                    self.handleApiError();
                                });
                        }.bind(this),

                        mapDragEnd: function(caller) {
                            var self = this;
                            var gmap = caller.gmap;

                            this.getPointsByLatLang(gmap.getCenter().lat(), gmap.getCenter().lng())
                                .then(function(pickUpPoints, statusText) {
                                    if (!!pickUpPoints.length && statusText === 'success') {
                                        self.renderPoints(gmap, pickUpPoints);
                                        self.renderRelativeParcelsList(gmap, pickUpPoints);
                                    }
                                })
                                .fail(function() {
                                    self.handleApiError();
                                });
                        }.bind(this),

                        autocomplete: function(caller, autocomplete) {
                            var place = autocomplete.getPlace();

                            if (!place.geometry) {
                                return false;
                            }

                            this.updatePlaceView(place.geometry.location);
                        }.bind(this),

                        search: function(caller, results) {
                            this.updatePlaceView(results[0].geometry.location);
                        }.bind(this),

                        mapError: function() {
                            this.handleErrorMap();
                        }.bind(this),

                        mapResize: function() {
                            var additionalWidth = this.elements.$mapPointsList ? this.options.pointsListMinWidth : 0;

                            this.elements.$map.width(
                                this.elements.$container.outerWidth() <= this.options.maxMapWidth + additionalWidth
                                    ? this.elements.$container.outerWidth()
                                    : this.options.maxMapWidth
                            );
                        }.bind(this),

                        infoWindowContentLoaded: function(caller, point) {
                            var $btnSelect = $('[data-pp-info-window-select]');

                            if (!!$btnSelect.length && !$btnSelect.bindSelectPointEvt) {
                                $btnSelect.bindSelectPointEvt = true;

                                $btnSelect.on('click', this.selectPickupPoint.bind(this, point.data));
                            }
                        }.bind(this),

                        infoWindowClose: this.unsetPointListItemActive.bind(this)
                    }
                });
            }

            if (this.elements.$container.hasClass('pp-pick-up-points_only-prepayment')) {
                this.elements.$container.removeClass('pp-pick-up-points_only-prepayment');
            }

            if (onlyPrepayment) {
                this.elements.$container.addClass('pp-pick-up-points_only-prepayment');
            }
        },

        /**
         * insert selected point to HTML as hidden input (set value to id of pp-point)
         */
        selectPickupPoint: function(point) {
            this.elements.modal.hideModal();
            this.elements.modal._hideMask();

            if (typeof this.callbacks.selected === 'function') {
                this.callbacks.selected(this, point);
            }
        },

        /**
         * handle error map.
         */
        handleErrorMap: function() {
            this.elements.$pickUpsListContainer.remove();

            if (Shop.googleMapsApi.isLoaderOpen) {
                Shop.googleMapsApi.removeLoader();
            }

            this.elements.modal._calculatePosition();
            this.elements.modal.reposition();
        },

        /**
         * create markup for infoWindow showed after click on point on map
         */
        generateInfoWindowMarkup: function(data) {
            var $infoStreet;
            var $openHoursList;
            var $footer;

            var $container = $('<div />', {
                class: data.type !== 'POCZTA' ? 'pp-pick-up-points__info-window_only-prepayment' : ''
            });

            if (data.type !== 'POCZTA') {
                $('<span />', {
                    class: 'pp-pick-up-points__prepayment-info',
                    text: Shop.lang.pickupsPoints.only_prepayment
                }).appendTo($container);
            }

            $('<h4 />', {
                class: 'pp-pick-up-points__infoHeader',
                text: data.name
            }).appendTo($container);

            $infoStreet = $('<div />', {
                class: 'pp-pick-up-points__infoStreet'
            }).appendTo($container);

            $infoStreet
                .append(
                    $('<span />', {
                        text: data.street
                    })
                )
                .appendTo($container);

            $openHoursList = $('<ul />', {
                class: 'pp-pick-up-points__infoOpenHours'
            }).appendTo($container);

            data.details.forEach(function(item) {
                $openHoursList.append(
                    $('<li />', {
                        class: 'pp-pick-up-points__infoHour',
                        text: item
                    })
                );
            });

            $footer = $('<div />', {
                class: 'pp-pick-up-points__footer'
            }).appendTo($container);

            $footer.append(
                $('<button />', {
                    class: 'btn btn-red pp-pick-up-points__infoBtnSelect',
                    'data-pp-info-window-select': '',
                    text: Shop.lang.common.select
                })
            );

            return $container.prop('outerHTML');
        },

        selectPoint: function() {},

        /**
         * call method which render provided points on map. Add additional info to point object
         */
        renderPoints: function(gmap, pickUpPoints) {
            var self = this;
            var data = this;
            var description;
            var pointsToRender = [];

            if (!!pickUpPoints.length) {
                pickUpPoints.forEach(function(pickUpPoint) {
                    if (!self.renderedPoints[pickUpPoint.pni]) {
                        description = pickUpPoint.description.split('#');

                        data = {
                            location: {
                                latitude: pickUpPoint.latitude,
                                longitude: pickUpPoint.longitude
                            },
                            data: pickUpPoint,
                            icon: self.icons[pickUpPoint.type],
                            infoWindowContent: self.generateInfoWindowMarkup({
                                pni: pickUpPoint.pni,
                                name: pickUpPoint.name,
                                street: pickUpPoint.street,
                                details: pickUpPoint.description.split('#'),
                                windowClick: self.selectPoint.bind(self),
                                type: pickUpPoint.type
                            }),
                            id: pickUpPoint.pni
                        };

                        self.renderedPoints[pickUpPoint.pni] = data;
                        pointsToRender.push(data);
                    }
                });

                self.objects.mapPoints.renderPoints(pointsToRender, this.setPointListItemActive.bind(self));
            }

            return this;
        },

        /**
         * call method which render provided points on map (push all points to this method). Add additional info to point object
         */
        renderAllPoints: function() {
            var self = this;
            var points;

            if (!this.allPointsLoaded) {
                this.getPoints()
                    .then(function(pickUpPoints, statusText) {
                        if (pickUpPoints && statusText === 'success') {
                            pickUpPoints = JSON.parse(pickUpPoints);
                            points = [];

                            if (!!pickUpPoints.length) {
                                pickUpPoints.forEach(function(pickUpPoint) {
                                    points.push({
                                        location: {
                                            latitude: pickUpPoint.y,
                                            longitude: pickUpPoint.x
                                        },
                                        data: pickUpPoint,
                                        id: pickUpPoint.p
                                    });
                                });

                                this.objects.mapPoints.renderPoints(points, this.pointClick);
                                this.allPointsLoaded = true;
                            }
                        }
                    })
                    .fail(function() {
                        self.handleApiError();
                    });
            }

            return this;
        },

        handleApiError: function() {
            this.elements.$pickUpsListContainer.empty();

            this.elements.$pickUpsListContainer.append('<div />', {
                text: Shop.lang.pickupsPoints.api_error
            });
        },

        /**
         * @param {jQuery DOM object} $activeElement - set scroll of active element on list, to make it visible all time, after list update (user move map, list got updated).
         */
        setPointsListScroll: function($activeElement) {
            var $parent = $activeElement.parents('.pp-pick-up-points__outposts-container');

            $parent.scrollTop($activeElement.get(0).offsetTop - $activeElement.parents('.pp-pick-up-points__outposts')[0].offsetTop);
        },

        /**
         * unset active list item
         */
        unsetPointListItemActive: function() {
            var $currentPoint;
            var activeClass = this.classes.ppOutpostItemActive;

            $currentPoint = $('.' + activeClass);

            this.currentPoint = null;

            if (!!$currentPoint.length) {
                $currentPoint.removeClass(activeClass);
            }
        },

        setPointListItemActive: function(context, point) {
            this.setActiveListItem($('[data-point-pni="' + point.id + '"]'));
        },

        /**
         * set active list item
         */
        setActiveListItem: function($pointToSet) {
            var activeClass = this.classes.ppOutpostItemActive;
            var $previous = $('.' + activeClass);

            if (!!$previous.length) {
                $previous.removeClass(activeClass);
            }

            if (!!$pointToSet.length) {
                $pointToSet.addClass(activeClass);
                this.currentPoint = $pointToSet.attr('data-point-pni');
                this.setPointsListScroll($pointToSet);
            }
        },

        handleListItemClick: function($li) {
            this.setActiveListItem($li.children('.pp-pick-up-points__outposts-item'));
        },

        parcelListItemClick: function(context, evt) {
            if (evt.target.nodeName === 'BUTTON' && evt.target.classList.contains('pp-pick-up-points__infoBtnSelect')) {
                this.selectPickupPoint(evt.currentTarget.data);
            } else {
                this.handleListItemClick($(evt.currentTarget));
            }
        },

        generateParcelsListItem: function(data) {
            var $container;
            var $details;
            var $header;
            var $figure;

            $container = $('<div />');

            $figure = $('<figure />', {
                class: 'outpost__figure'
            }).append(
                $('<img />', {
                    src: data.icon
                })
            );

            $header = $('<div />', {
                class: 'outpost__header'
            }).append($figure);

            if (data.type !== 'POCZTA') {
                $header.append(
                    $('<span />', {
                        text: Shop.lang.pickupsPoints.only_prepayment,
                        class: 'outpost__prepayment-info'
                    })
                );
            }

            $header.append(
                $('<h5 />', {
                    class: 'outpost__name',
                    text: data.name
                })
            );

            $header.append(
                $('<h6 />', {
                    class: 'outpost__street',
                    text: data.street
                })
            );

            $header.appendTo($container);

            $details = $('<ul />', {
                class: 'outpost__description'
            }).appendTo($container);

            data.details.forEach(function(item) {
                $('<li />', {
                    class: 'outpost__description-item',
                    text: item
                }).appendTo($details);
            });

            $('<button />', {
                class: 'btn btn-red pp-pick-up-points__infoBtnSelect',
                text: Shop.lang.common.select
            }).appendTo($container);

            return $container;
        },

        renderRelativeParcelsList: function(gmap, pickUpPoints) {
            var self = this;
            var modalCurrentWidth;
            var pickUpPointsList = [];
            var details;
            var $activeListItem;

            pickUpPoints.forEach(function(pickUpPoint) {
                details = pickUpPoint.description.split('#');
                details.pop();

                pickUpPointsList.push({
                    $pointDOM: $('<div />', {
                        class:
                            'pp-pick-up-points__outposts-item' +
                            (self.currentPoint === pickUpPoint.pni ? ' ' + self.classes.ppOutpostItemActive : '') +
                            (pickUpPoint.type !== 'POCZTA' ? ' ' + self.classes.ppOutpostOnlyPrepayment : ''),
                        'data-point-pni': pickUpPoint.pni,
                        html: self.generateParcelsListItem({
                            name: pickUpPoint.name,
                            street: pickUpPoint.street,
                            details: details,
                            icon: self.icons[pickUpPoint.type],
                            type: pickUpPoint.type
                        })
                    }),
                    data: pickUpPoint,
                    id: pickUpPoint.pni
                });
            });

            this.objects.mapPoints.renderPointsList(pickUpPointsList, this.parcelListItemClick.bind(this), true);

            modalCurrentWidth = this.elements.modal.object.container.outerWidth();
            if (modalCurrentWidth !== this.elements.modalsWidth) {
                this.elements.modalsWidth = modalCurrentWidth;
                this.elements.modal._calculatePosition();
                this.elements.modal.reposition();
            }

            $activeListItem = $('.' + this.classes.ppOutpostItemActive);
            if (!!$activeListItem.length) {
                this.setPointsListScroll($activeListItem);
            }

            return this;
        },

        getPoints: function() {
            var self = this;

            return $.ajax({
                url: 'https://mapa.ecommerce.poczta-polska.pl/widget/pickuppoints/json/getAll',
                method: 'post',
                contentType: 'application/json',
                data: JSON.stringify({
                    type: self.pointTypes
                })
            });
        },

        getPointsByLatLang: function(lat, lng) {
            var self = this;

            return $.ajax({
                url: 'https://mapa.ecommerce.poczta-polska.pl/widget/pickuppoints/json/getByLatLng/',
                method: 'post',
                contentType: 'application/json',
                data: JSON.stringify({
                    type: self.pointTypes,
                    latitude: lat,
                    longitude: lng
                })
            });
        },

        updateMapAndView: function(gmap, centerLoc, zoomLevel) {
            if (!gmap) {
                return false;
            }

            if (centerLoc) {
                gmap.setCenter(centerLoc);
            }

            if (zoomLevel) {
                gmap.setZoom(zoomLevel);
            }
        },

        clearMapView: function() {
            if (this.objects.mapPoints) {
                this.objects.mapPoints.closeInfoWindow();

                if (!!$('.' + this.classes.ppOutpostItemActive).length) {
                    $('.' + this.classes.ppOutpostItemActive).removeClass(this.classes.ppOutpostItemActive);
                }

                if (this.options.$searchContainer) {
                    this.options.$searchContainer.val('');
                }
            }
        }
    });
})();
