;(function (undefined) {
	/**
     * Use for loading google maps and clusterer libraries.
     * @param {jQuery DOM object} $loaderContainer - container element for loader DOM created dynamicly
     */
    Shop.GoogleMapsAPI.include({
        options: {
            $loaderContainer: null,
            googleMapsApikey: null
        },

        elements: {
            $loader: null
        },

        EARTH_RADIUS: 6378137,

        isApiReady: false,
        isLoaderOpen: false,

        /**
         * call function responsible for creating loader, add google map API dynamic script to download
         */
        initialize: function (options) {
            this.constructor.setOptions(options);

            if (this.options.$loaderContainer && !!this.options.$loaderContainer.length) {
                this.addLoader();
            }
            this.embedGoogleMapApi();
        },

        /**
         * create loader markup and append it to provided $loader container
         */
        addLoader: function () {
            this.elements.$loader = $('<div />', {
                'class': 'pp-pick-up-points__map-loader',
            }).append($('<div />', {
                'class': 'pp-pick-up-points__map-loader-content',
                'text': navigator.geolocation ? Shop.lang.pickupsPoints.searching_location : Shop.lang.common.loading
            }));

            this.isLoaderOpen = true;
            this.elements.$loader.appendTo(this.options.$loaderContainer);
        },

        /**
         * remove loader from DOM
         */
        removeLoader: function () {
            if (this.elements.$loader) {
                this.elements.$loader.remove();
                this.isLoaderOpen = false;
            }
        },

        /**
         *  measure distance between two points in distance
         *  @param {object} p1 - {lat, lng}
         *  @param {object} p2 - {lat, lng}
         *  @return {number} distance in meters
         */
        getDistance: function (p1, p2) {
            var dLat;
            var dLong;
            var a;

            dLat = this.rad(p2.lat() - p1.lat());
            dLong = this.rad(p2.lng() - p1.lng());
            a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.rad(p1.lat())) * Math.cos(this.rad(p2.lat())) * Math.sin(dLong / 2) * Math.sin(dLong / 2);

            return this.EARTH_RADIUS * 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        },

        /**
         * called when google maps will be ready. Fire global event indicating that
         */
        apiReady: function () {
            this.parent.fireEvent('GoogleMapsAPI:ready');
            this.isApiReady = true;
        },

        /**
         * create dynamic script tag to download google maps and clusterer
         */
        embedGoogleMapApi: function () {
            var doc;
            var script;
            var id;
            var fjs;
            var js;

            doc = document;
            script = 'script';

            id = 'g-mapclusterer';
            fjs = doc.getElementsByTagName(script)[0];
            if (doc.getElementById(id)) {
                return;
            }

            js = doc.createElement(script);
            js.id = id;
            js.src = 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js';
            js.async = false;
            js.defer = false;
            fjs.parentNode.insertBefore(js, fjs);

            id = 'g-map';
            fjs = doc.getElementsByTagName(script)[0];
            if (doc.getElementById(id)) {
                return;
            }

            js = doc.createElement(script);
            js.id = id;
            js.src = 'https://maps.googleapis.com/maps/api/js?key=' + this.options.googleMapsApikey + '&libraries=places&callback=googleMapsAPICallback&language=' + Shop.lang.name.slice(0,2);
            js.async = false;
            js.defer = false;
            fjs.parentNode.insertBefore(js, fjs);
        },

        /**
         * convert degree to radian
         * @param {number} degrees
         * @returns {number} radian
         */
        rad: function (x) {
            return x * Math.PI / 180;
        }

    });
})();