import {
    ANALYTICS_CONSENT_NAME,
    FUNCTIONAL_CONSENT_NAME,
    LIST_OF_CONSENT_NAMES,
    MARKETING_CONSENT_NAME,
    PLAFTORM_CONSENT_NAME
} from '@frontstoreRwd/modules/consents/constants';

import { $on } from '@core/tools/event_bus';
import { CONSENT_MODAL_CREATED } from '@frontstoreRwd/modules/consents/classes/consents_modal/event_names';
import { ConsentsAnalytics } from '@frontstoreRwd/modules/consents/classes/consents_analytics';
import { ConsentsApi } from '@frontstoreRwd/modules/consents/classes/consents_api';
import { ConsentsGroup } from '@frontstoreRwd/modules/consents/classes/consents_group';
import { ConsentsModal } from '@frontstoreRwd/modules/consents/classes/consents_modal';
import { ConsentsStore } from '@frontstoreRwd/modules/consents/classes/consents_store';
import { FULL_CONSENT_WIDGET } from '../../constants';
import { ICustomerPrivacyApi } from '../types';
//@ts-ignore
import jquery from 'jquery';

export class CustomerPrivacyApi implements ICustomerPrivacyApi {
    private _consentsApi: ConsentsApi;
    private _isEnabled: boolean = false;
    private _isFullWidget: boolean = false;

    constructor() {
        const consentsStore = new ConsentsStore();
        const consentsGroup = new ConsentsGroup(LIST_OF_CONSENT_NAMES);
        this._consentsApi = new ConsentsApi(consentsGroup, consentsStore);

        jquery(document).ready(() => {
            //@ts-ignore
            this._isFullWidget = Shop.cookiesViewType === FULL_CONSENT_WIDGET;

             //@ts-ignore
             if (Shop.displayCookieInfo === '1' && this._isFullWidget) {
                this.enable();
            }

            if (this._isEnabled && this._isFullWidget) {
                new ConsentsModal(this._consentsApi);
            }
        });

        $on(CONSENT_MODAL_CREATED, () => {
            new ConsentsAnalytics();
        });
    }

    public enable (): void {
        this._isEnabled = true;
    }

    public disable (): void {
        this._isEnabled = false;
    }

    public onAnalyticsConsentGranted(callback: () => void): void {
        if (this._isEnabled){ 
            this._consentsApi.onConsentGranted(ANALYTICS_CONSENT_NAME, callback);
        } else {
            callback();
        }
    }

    public onMarketingConsentGranted(callback: () => void): void {
        if (this._isEnabled){ 
            this._consentsApi.onConsentGranted(MARKETING_CONSENT_NAME, callback);
        } else {
            callback();
        }
    }

    public onFunctionalConsentGranted(callback: () => void): void {
        if (this._isEnabled){ 
            this._consentsApi.onConsentGranted(FUNCTIONAL_CONSENT_NAME, callback);
        } else {
            callback();
        }
    }

    public onPlatformConsentGranted(callback: () => void): void {
        if (this._isEnabled){ 
            this._consentsApi.onConsentGranted(PLAFTORM_CONSENT_NAME, callback);
        } else {
            callback();
        }
    }
}
