export const CONSENT_CHECKBOX_SELECTOR = '.js__consent';

export const BASIC_VIEW_SELECTOR = '.js__basic-view';
export const ADVANCED_VIEW_SELECTOR = '.js__advanced-view';

export const SHOW_BASIC_VIEW_SELECTOR = '.js__show-basic-view';
export const SHOW_ADVANCED_VIEW_SELECTOR = '.js__show-advanced-view';
export const MASK_SELECTOR = '.consents__mask';

export const ACCEPT_BUTTON_SELECTOR = '.js__save-consents';
export const ACCEPT_ALL_BUTTON_SELECTOR = '.js__accept-all-consents';
export const SELECT_ALL_CONSENTS_SELECTOR = '.js__select-all-consents';

export const CONSENT_MODAL_ID = 'consents-modal';
export const HIDE_CLASS = 'none';
export const POPUP_CLASS = 'consents_popup';
