import customerPrivacy from '@frontstoreRwd/modules/customer_privacy/index';

(function(undefined) {
    Shop.xhrBox.include({
        options: {
            xhrSelector: '.box-xhr',
            productSelector: '#box_productfull',
            currentProductId: null,
            limit: 30
        },

        initialize: function(options) {
            var self;
            var recentViewProducts = JSON.parse(localStorage.getItem('recentViewProducts')) || [];
            var recentProductIndex;
            this.constructor.setOptions(options);

            customerPrivacy.onFunctionalConsentGranted(() => {
                if ($(this.options.productSelector).length > 0) {
                    this.options.currentProductId = parseInt(
                        $('body')
                            .attr('id')
                            .replace('shop_product', ''),
                        10
                    );

                    recentProductIndex = recentViewProducts.indexOf(this.options.currentProductId);
                    if (recentProductIndex !== -1) {
                        recentViewProducts.splice(recentProductIndex, 1);
                    }

                    recentViewProducts.unshift(this.options.currentProductId);

                    if (recentViewProducts.length > this.options.limit) {
                        recentViewProducts = recentViewProducts.slice(0, this.options.limit);
                    }

                    try {
                        $.cookie('lastProducts-' + Shop.lang.name, recentViewProducts.toString());
                        localStorage.setItem('recentViewProducts', JSON.stringify(recentViewProducts));
                    } catch (e) {
                        console.error(e);
                    }
                }

                self = this;
                $.each($(this.options.xhrSelector), function() {
                    var el = $(this);
                    var req = $.ajax({
                        url: '/box/render',
                        method: 'get',
                        async: true,
                        cache: false,
                        headers: {
                            'X-Cookie': 'lastProducts-' + Shop.lang.name + '=' + recentViewProducts.toString()
                        },
                        data: {
                            engine: $(this).data('engine'),
                            id: $(this).data('id')
                        }
                    });

                    req.done(function(data) {
                        var $boxRecent;
                        var boxSlider;

                        el.html(data);
                        el.removeClass('loading');

                        $boxRecent = el.find('#box_recent');
                        boxSlider = self.parent.selectorFunctions.boxslider;

                        if ($boxRecent.hasClass('slider') && self.parent.status.load && !$boxRecent.selectorFnTriggered) {
                            boxSlider.load($boxRecent, window.shoper);
                        }

                        if (!self.parent.lazypicture) {
                            self.parent['lazypicture'] = new self.parent.classes.LazyPicture();
                        }

                        self.parent.lazypicture.updateImagesList();
                    });
                });
            });
        }
    });
})();
