(function(undefined) {
    Shop.ShippingCosts.include({
        options: {
            stockId: null,
            price: null,
            activeCountry: null,
            activeCountryId: null,
            shippingCountries: null,
            shippings: null,
            country2shipping: null,
            showCountryName: false,

            selectors: {
                stockId: '#box_productfull input[type="hidden"][name="stock_id"]',
                price: '[itemprop = "price"]',
                productDeliveriesId: '#box_productdeliveries',
                productDeliveriesClass: '.box_productdeliveries',
                productDeliveriesTab: '[data-tab = "box_productdeliveries"]',
                deliveryContainer: '.product-deliveries .delivery-container',
                shippingCountry: '.shipping-country',
                countrySelect: '.shipping-country-select',
                shippings: '.shippings',
                shippingCosts: '.shipping-costs',
                lowestCost: '.lowest-cost',
                lowestCostShipping: '.lowest-cost-shipping',
                lowestCostShippingCountry: '.lowest-cost-shipping-country',
                stocks: '.stocks'
            }
        },

        initialize: function(options) {
            this.constructor.setOptions(options);

            if (!!$('.loyaltyexchange').length) {
                $('#box_productdeliveries').remove();
                return;
            }

            $(this.options.selectors.countrySelect).hide();

            this.options.stockId = parseFloat($(this.options.selectors.stockId)[0].value);
            this.options.price = parseFloat($(this.options.selectors.price)[0].innerHTML);

            if (!!$(this.options.selectors.stocks).length === true) {
                if (this.parent.base64.decode(Shop.values.OptionsDefault) === 'null') {
                    this.getShippingsData(this.options.stockId, this.options.price);
                }

                this.parent.addEvent('stock:change', this.handleStockChange.bind(this));
            } else {
                this.getShippingsData(this.options.stockId, this.options.price);
            }

            $(this.options.selectors.shippingCountry).on('change', this.handleCountryChange.bind(this));
        },

        getShippingsData: function(stock_id, overwrite_price) {
            var self = this;

            $.ajax({
                url: self.parent.url('product/getstockcostinfo/stock/' + stock_id + '/price/' + overwrite_price),
                method: 'get',

                success: function(response) {
                    if (response && !!response.shippings.length === true) {
                        $(self.options.selectors.shippingCosts).show();

                        if (!!$(self.options.selectors.productDeliveriesId).length === true) {
                            $(self.options.selectors.productDeliveriesId).show();
                        } else {
                            $(self.options.selectors.productDeliveriesClass).show();
                            $(self.options.selectors.productDeliveriesTab).show();
                        }

                        self.options.shippingCountries = response.shipping_countries;
                        self.options.shippings = response.shippings;

                        if (!self.options.activeCountry) {
                            if (!!response.shipping_countries[response.shipping_country] === true) {
                                self.options.activeCountry = response.shipping_countries[response.shipping_country];
                                self.options.activeCountryId = response.shipping_country;
                            } else {
                                self.options.activeCountry = response.shipping_countries[Object.keys(response.shipping_countries)[0]];
                                self.options.activeCountryId = Object.keys(response.shipping_countries)[0];

                                self.options.showCountryName = true;
                            }
                        }

                        self.options.country2shipping = response.country2shipping;

                        if (Object.keys(self.options.shippingCountries).length > 1) {
                            self.options.showCountryName = true;
                            self.createSelectCountryOptions(self.options.shippingCountries, self.options.activeCountryId);
                        }

                        self.createShippingsList(self.options.shippings, self.options.country2shipping[self.options.activeCountryId]);
                    } else {
                        $(self.options.selectors.shippingCosts).hide();

                        if (!!$(self.options.selectors.productDeliveriesId).length === true) {
                            $(self.options.selectors.productDeliveriesId).hide();
                        } else {
                            $(self.options.selectors.productDeliveriesClass).hide();
                            $(self.options.selectors.productDeliveriesTab).hide();
                        }
                    }
                }
            });
        },

        handleStockChange: function(e, stock) {
            this.options.stockId = stock.sid;
            this.options.price = this.parent.toPrimaryValue(stock.price);

            this.getShippingsData(this.options.stockId, this.options.price);
        },

        handleCountryChange: function(e) {
            var self = this;

            $(this.options.selectors.deliveryContainer)
                .find('.country')
                .each(function() {
                    if ($(this).attr('selected')) {
                        $(this).attr('selected', false);
                    }

                    if (e.target.value === $(this).val()) {
                        self.options.activeCountryId = parseFloat($(this).data('country-id'));
                        self.options.activeCountry = $(this).val();
                        $(this).attr('selected', true);
                    }
                });

            this.createShippingsList(this.options.shippings, this.options.country2shipping[this.options.activeCountryId]);
        },

        createSelectCountryOptions: function(countries, activeCountryId) {
            $(this.options.selectors.countrySelect).show();

            $(this.options.selectors.shippingCountry).empty();

            const sortedCountries = Object.entries(countries).sort(([, countryAName], [, countryBName]) => {
                return countryAName.localeCompare(countryBName);
            });

            sortedCountries.forEach(([countryId, countryName]) => {
                countryId = parseFloat(countryId);

                const $option = $('<option>', {
                    text: countryName,
                    'data-country-id': countryId,
                    class: 'country',
                    selected: countryId === activeCountryId ? true : false
                });

                $(this.options.selectors.shippingCountry).append($option);
            });
        },

        createShippingsList: function(shippings, availableShippings) {
            var $shippingsContainer = $(this.options.selectors.shippings);
            var personalDeliveries = [];
            var otherDeliveries = [];

            $shippingsContainer.empty();

            availableShippings.forEach(function(availableShipping) {
                if (!!shippings.length === true) {
                    shippings.forEach(function(shipping) {
                        if (availableShipping.id === parseFloat(shipping.id)) {
                            var $singleShipping = $('<div>').addClass('shipping row f-row');
                            var $shippingLabelContainer = $('<div>').addClass('shipping-label-container f-grid-9');
                            var $shippingLabel = $('<span>').addClass('shipping-label');
                            var $shippingCost = $('<div>').addClass('shipping-cost f-grid-3');
                            var lowestCost = availableShipping.lowestCostWithCurrency;
                            var shippingName = shipping.name;
                            var shippingDescription = shipping.description;
                            var shippingEngine = shipping.engine;

                            if (!availableShipping.lowestCost) {
                                $singleShipping.data('free-delivery', true);
                            }

                            $shippingLabel.html(shippingName);
                            $shippingLabelContainer.append($shippingLabel);

                            if (shippingDescription) {
                                $shippingLabelContainer.append(' (' + shippingDescription + ')');
                            }

                            $shippingCost.html(lowestCost);
                            $singleShipping.append($shippingLabelContainer, $shippingCost);

                            if (shippingEngine === 'personal') {
                                personalDeliveries.push($singleShipping);
                            } else {
                                otherDeliveries.push($singleShipping);
                            }
                        }
                    });
                }
            });

            if (!!personalDeliveries.length === true) {
                personalDeliveries.reverse().forEach(function(personal) {
                    $shippingsContainer.prepend(personal);
                });
            }

            if (!!otherDeliveries.length === true) {
                otherDeliveries.reverse().forEach(function(other) {
                    $shippingsContainer.prepend(other);
                });
            }

            this.handleLowestCostShippingChange($shippingsContainer.find('.shipping:first-child'), otherDeliveries.length);
        },

        handleLowestCostShippingChange: function($firstDelivery, otherDeliveriesCount) {
            var $shippingsContainer = $(this.options.selectors.shippings);
            var $lowestCost = $(this.options.selectors.lowestCost);
            var lowestCostShipping = $firstDelivery.find('.shipping-label').html();
            var isDeliveryFree = $firstDelivery.data('free-delivery');
            var deliveryCost = $firstDelivery.find('.shipping-cost').html();
            var prefixFrom = $shippingsContainer.data('cost-from');
            var prefixFree = $shippingsContainer.data('cost-free');

            if (isDeliveryFree && prefixFree) {
                $lowestCost.html(prefixFree);
            } else if (otherDeliveriesCount > 1 && prefixFrom) {
                $lowestCost.html(prefixFrom + ' ' + deliveryCost);
            } else {
                $lowestCost.html(deliveryCost);
            }

            if (!isDeliveryFree) {
                $(this.options.selectors.lowestCostShipping).html('- ' + lowestCostShipping);

                if (this.options.showCountryName) {
                    $(this.options.selectors.lowestCostShippingCountry).html('(' + this.options.activeCountry + ')');
                }
            }
        }
    });
})();
