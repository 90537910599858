;(function (undefined) {
    var MAX_CHAR_PRODUCT_NAME = 28;

    var HIDE_CLASS = 'none';
    var SEARCH_CLASS = 'search';
    var SEARCH_CONTENT_CLASS = SEARCH_CLASS + '__content';
    var SEARCH_CONTENT_RIGHT_CLASS = SEARCH_CONTENT_CLASS + '_right';
    var SEARCH_ITEM_CLASS = SEARCH_CLASS + '__item';
    var SEARCH_ITEM_CONTENT_CLASS = SEARCH_ITEM_CLASS + '_content';
    var SEARCH_OPEN_CLASS = SEARCH_CLASS + '_open';
    var SEARCH_OPEN_EMPTY_CLASS = SEARCH_OPEN_CLASS + '-empty';
    var SEARCH_JS_CLASS = 'js__search';
    var SEARCH_EMPTY_INPUT_VIEW_CLASS = SEARCH_JS_CLASS + '-empty-input-view';
    var SEARCH_ERROR_VIEW_CLASS = SEARCH_JS_CLASS + '-erros';
    var SEARCH_XHR_CONTENT_CLASS = SEARCH_JS_CLASS + '-xhr-content';
    var SEARCH_SECTION_CLASS = SEARCH_CLASS + '__section';
    var SEARCH_SECTION_ACTIVE_CLASS = SEARCH_SECTION_CLASS + '_active';
    var SEARCH_LIST_ITEM_CLASS = SEARCH_CLASS + '__list-item';
    var SEARCH_LIST_ITEM_ACTIVE_CLASS = SEARCH_LIST_ITEM_CLASS + '_active';

    var SEARCH_CATEGORY_URL = '/{lang}/c/{categoryName}/{categoryId}/1/{view}/1/searchquery/{searchQuery}';

    Shop.SearchView = function (options) {
        this.options = options || {
            $el: null,
            history: null,
            startPhrases: null,
            searchInstance: null,
            mobileBtn: false,
            cutProductName: false,
            showProductsScore: false,
            showProductsAvailability: false,
            dynamicResultLoading: false,
            onResultMoved: null
        };

        this.elements = {
            $search: this.options.$el,
            $content: null,
            $errorSection: null,
            $emptyInputSection: null,
            $resultSection: null,
            $loader: null,
            $btnClear: null,
        };

        this.objects = {
            history: this.options.history,
            startPhrases: this.options.startPhrases,
            suggestionsTemplate: new Shop.SearchTemplate({
                type: Shop.Search.SEARCH_GLOBAL_SETTINGS.suggestions
            }),
            resultsProductsTemplate: new Shop.SearchTemplate({
                type: Shop.Search.SEARCH_GLOBAL_SETTINGS.products
            }),
            resultsProducersTemplate: new Shop.SearchTemplate({
                type: Shop.Search.SEARCH_GLOBAL_SETTINGS.producers
            }),
            search: this.options.searchInstance
        };

        this.callbacks = {
            checkMobileBtn: this.parent.debounce(_checkMobileBtn.bind(this), 200)
        };

        this.initialize();
    };

    Shop.SearchView.prototype = {
        constructor: Shop.SearchView,

        parent: Shop.prototype,

        messagesTypes: {
            emptyInput: 0,
            emptyInputHistory: 1,
            noResult: 2,
            searchError: 3
        },

        viewTypes: {
            history: 0,
            startPhrases: 1,
            suggestions: 2,
            results: 3,
            message: 4
        },

        initialize: function () {
            var self = this;

            if (this.options.mobileBtn && this.elements.$search.parents('.logo-bar').length) {
                this.objects.mobileBtn = new Shop.SearchMobileBtn({
                    onClick: function () {
                        self.open();
                    }
                });
            }

            this.skeletonRendered = false;

            _cacheDOM(this);
            _bindEvents(this);

            this.settings = {
                lang: Shop.lang.name.slice(0, 2),
                productsViewType: this.elements.$search.data('productViewType'),
                mobileBtnOffsetVisible: 100
            };

            return this;
        },

        open: function () {
            if (!this.skeletonRendered) {
                this.renderSkeleton();
            }

            if (this.isOpen()) {
                return;
            }

            this.elements.$search.addClass(SEARCH_OPEN_CLASS);
            this.parent.fireEvent('search:open', this.objects.search);

            if (typeof this.objects.search.options.onSearchOpen === 'function') {
                this.objects.search.options.onSearchOpen();
            }

            if (this.parent.rwd.small) {
                $(document.body).addClass('scroll_block');
                this.elements.$search.addClass('visible_block');
                this.elements.$input.focus();
            }

            if (this.objects.mobileBtn && this.parent.rwd.xs) {
                this.objects.mobileBtn.hide();
            }

            this.reposition();

            return this;
        },

        reposition: function () {
            var $content = this.elements.$content;
            var clientWidth = document.documentElement.clientWidth;
            var elementBCR = $content.get(0).getBoundingClientRect();

            if (elementBCR.right > clientWidth
				&& elementBCR.width < (elementBCR.left + this.elements.$search.get(0).offsetWidth)) {
                $content.addClass(SEARCH_CONTENT_RIGHT_CLASS);
            }

            return this;
        },

        showElement: function ($el) {
            $el.removeClass(HIDE_CLASS);

            return this;
        },

        hideElement: function ($el) {
            $el.addClass(HIDE_CLASS);

            return this;
        },

        showLoader: function () {
            this.elements.$btnClear.addClass(HIDE_CLASS);
            this.elements.$loader.removeClass(HIDE_CLASS);

            return this;
        },

        hideLoader: function () {
            var inputValue = this.elements.$input.prop('value');

            if (inputValue && inputValue.length >= 1) {
                this.elements.$btnClear.removeClass(HIDE_CLASS);
            }

            this.elements.$loader.addClass(HIDE_CLASS);

            return this;
        },

        isOpen: function () {
            return this.elements.$search.hasClass(SEARCH_OPEN_CLASS);
        },

        close: function () {
            this.elements.$search.removeClass(SEARCH_OPEN_CLASS);

            if (this.elements.$content.hasClass(SEARCH_CONTENT_RIGHT_CLASS)) {
                this.elements.$content.removeClass(SEARCH_CONTENT_RIGHT_CLASS);
            }

            if (this.objects.mobileBtn && this.parent.rwd.xs) {
                this.objects.mobileBtn.show();
            }

            if (this.parent.rwd.small) {
                $(document.body).removeClass('scroll_block');
                this.elements.$search.removeClass('visible_block');
            }

            this.parent.fireEvent('search:close', this.objects.search);

            if (typeof this.objects.search.options.onSearchClose === 'function') {
                this.objects.search.options.onSearchClose();
            }

            this.elements.$input.blur();

            return this;
        },

        /*
			* Return current visible search section (result|errors|(history|start phrases))
			*/
        getActiveSection: function () {
            return this.elements.$search.find('.' + SEARCH_ITEM_CONTENT_CLASS + ':not(.' + HIDE_CLASS + ')');
        },

        /**
		 * @param TYPE - element type to render
		 * @param options - options for particular render view function
		 * @param settings - setting for "global" render function
		 */
        render: function (type, options, settings) {
            var $container;
            var currObject = null;

            settings = settings || {};
            options = options || {};

            if (!settings.preserveView) {
                this.clearView();
            }

            switch (type) {
                case this.viewTypes.history:
                    currObject = this.objects.history;
                    break;
                case this.viewTypes.startPhrases:
                    currObject = this.objects.startPhrases;
                    break;

                case this.viewTypes.suggestions:
                    this.renderSuggestions(options);
                    break;

                case this.viewTypes.results:
                    this.renderResults(options);
                    break;

                case this.viewTypes.message:
                    this.renderMessage(options);
                    break;
            }

            if (currObject) {
                $container = this.elements.$search.find('.' + SEARCH_EMPTY_INPUT_VIEW_CLASS);
                $container.eq(0).removeClass(HIDE_CLASS);
                $container.append(currObject.template());
            }

            return this;
        },

        /**
		 *
		 * @param {Integer} type - result type to redner
		 * @param {*} response - response object, contains reponse data and response settings
		 * @param {*} options
		 */
        renderMore: function (type, response, options) {
            options = options || {};

            switch (type) {
                case Shop.Search.SEARCH_GLOBAL_SETTINGS.producers.id:
                    this.objects.resultsProducersTemplate.appendListItems((this.getProducersMarkup(response.data, _getInputRegExp(this), options)));
                    break;
                case Shop.Search.SEARCH_GLOBAL_SETTINGS.products.id:
                    this.objects.resultsProductsTemplate.appendListItems((this.getProductsMarkup(response.data, _getInputRegExp(this), options)).markup);
                    break;
            }

            return this;
        },

        clearView: function (cleanMarkup, isInputEmpty) {
            this.elements.$errorSection.empty();
            this.elements.$errorSection.addClass(HIDE_CLASS);

            this.elements.$emptyViewSection.empty();
            this.elements.$emptyViewSection.addClass(HIDE_CLASS);

            this.elements.$resultSection.empty();
            this.elements.$resultSection.addClass(HIDE_CLASS);

            if (isInputEmpty) {
                this.elements.$search.addClass(SEARCH_OPEN_EMPTY_CLASS);
            } else {
                this.elements.$search.removeClass(SEARCH_OPEN_EMPTY_CLASS);
            }

            if (cleanMarkup) {
                this.objects.history.clean();

                this.objects.suggestionsTemplate.clean();
                this.objects.resultsProductsTemplate.clean();
                this.objects.resultsProducersTemplate.clean();

                if (this.objects.startPhrases) {
                    this.objects.startPhrases.clean();
                }
            }

            return this;
        },

        renderSkeleton: function () {
            var $documentFragment = $(document.createDocumentFragment());

            this.elements.$errorSection = $('<div/>', {
                'class': HIDE_CLASS + ' ' + SEARCH_ITEM_CONTENT_CLASS + ' ' + SEARCH_ERROR_VIEW_CLASS
            }).appendTo($documentFragment);

            this.elements.$emptyViewSection = $('<div/>', {
                'class': HIDE_CLASS + ' ' + SEARCH_ITEM_CONTENT_CLASS + ' ' + SEARCH_EMPTY_INPUT_VIEW_CLASS
            }).appendTo($documentFragment);

            this.elements.$resultSection = $('<div/>', {
                'class': HIDE_CLASS + ' ' + SEARCH_ITEM_CONTENT_CLASS + ' ' + SEARCH_XHR_CONTENT_CLASS
            }).appendTo($documentFragment);

            this.elements.$content.append($documentFragment);
            this.skeletonRendered = true;

            return this;
        },

        /**
		 *
		 * @param {*} data
		 * @param {*} regExp
		 * @returns - return string array of procuders markup
		 */
        getProducersMarkup: function (data, regExp) {
            var producerName;
            var regExpMatch;

            /**
			 * Maps producers data to markup and return it
			 */
            return data.list.map(function (producer) {
                regExpMatch = false;
                producerName = producer.name;

                if (regExp && regExp.test(producerName)) {
                    regExpMatch = true;
                    producerName = producerName.replace(regExp, '<span class="search__result_match">$&</span>');
                }

                return _resultTemplate({
                    resultType: 'Vendor',
                    url: producer.url,
                    resultIcon: (producer.img ? '<img src="' + producer.img + '" alt="prduct image">' : ''),
                    title: producerName,
                    cssTitle: (regExpMatch ? ' search__result_bold' : '')
                });
            });
        },

        getCategoryMarkup: function (data, regExp) {
            var categoryName;
            var regExpMatch;
            var $suggestRestriction = $(document.createDocumentFragment());
            var self = this;

            data.list.forEach(function (category) {
                var categoryMatch;

                regExpMatch = false;
                categoryName = category.name;

                if (regExp) {
                    categoryMatch = regExp.exec(categoryName);
                    if (categoryMatch) {
                        regExpMatch = true;
                        categoryName = categoryName.replace(categoryName.slice(0, (categoryMatch.index + categoryMatch[0].length)), '<span class="search__result_match">$&</span>');
                    }
                }

                $suggestRestriction.append(self.objects.suggestionsTemplate.getListItemTemplate({
                    markup: '<a class="js__search-result-link" data-search-result-type="Category" href="' + category.url + '">' + _suggestTemplate(categoryName, regExpMatch) + ' <span class="search__result-category-title">' + Shop.lang.search.category + '</span></a>',
                    attributes: {
                        'data-search-sugest-value': category.name
                    },
                    cssClasses: (regExpMatch ? ' search__result_bold' : undefined)
                }));
            });

            return $suggestRestriction;
        },

        getProductsMarkup: function (data, regExp, options) {
            var self = this;
            var isPromo;
            var productInCategoryUrl;
            var categoriesArr;
            var suggestName;
            var categoryName;
            var score;
            var productName;
            var productRate;
            var resultNote;
            var rateList;
            var resultInfo;
            var starIcon;
            var $suggestRestriction = $(document.createDocumentFragment());
            var productsMarkup = [];
            var regExpMatch;
            var origSuggestion;
            var categoriesString;

            productsMarkup = data.list.map(function (product, index) {
                productName = product.name;
                productRate = parseFloat(product.rate);
                resultNote = '';
                rateList = '';
                resultInfo = '';
                starIcon;
                score = 5;
                regExpMatch = false;

                if (self.options.cutProductName && product.name.length > MAX_CHAR_PRODUCT_NAME) {
                    productName = product.name.substring(0, MAX_CHAR_PRODUCT_NAME) + '...';
                }

                if (index === 0 && product.category && options.firstSuggestion) {
                    categoryName = product.category.name;
                    origSuggestion = options.firstSuggestion.toLowerCase();
                    suggestName = options.firstSuggestion.toLowerCase();

                    if (regExp && (regExp.test(categoryName) || regExp.test(suggestName))) {
                        regExpMatch = true;
                        categoryName = categoryName.replace(regExp, '<span class="search__result_match">$&</span>');
                        suggestName = suggestName.replace(regExp, '<span class="search__result_match">$&</span>');
                    }

                    productInCategoryUrl = self.parent.substitute(SEARCH_CATEGORY_URL, {
                        lang: self.settings.lang,
                        categoryName: product.category.name,
                        categoryId: product.category.id,
                        view: self.settings.productsViewType,
                        searchQuery: options.firstSuggestion
                    });

                    categoriesArr = product.category.breadcrumbs.split('>');
                    categoriesString = categoriesArr.splice(categoriesArr.length - 2).join('>');

                    $suggestRestriction.prepend(self.objects.suggestionsTemplate.getListItemTemplate({
                        markup: '<a class="js__search-result-link" data-search-result-type="Product" href="' + productInCategoryUrl + '">' + _suggestTemplate(suggestName, regExpMatch) + ' ' + '<span class="search__result_regular">' + Shop.lang.search.in_category + '</span> ' + categoriesString + '</a>',
                        attributes: {
                            'data-search-sugest-value': origSuggestion
                        },
                        cssClasses: (regExpMatch ? ' search__result_bold' : undefined)
                    }));
                }

                if (self.options.showProductsScore && productRate > 0) {
                    while (score) {
                        starIcon = 'icon-star-full';
                        if (productRate < 1) {
                            starIcon = productRate > 0 ? 'icon-star-half' : 'icon-star-empty';
                        }

                        productRate -= 1;
                        score -= 1;
                        rateList += '<li><span class="search__votestars-item icon ' + starIcon + '"></li>';
                    }
                    resultNote = '<ul class="search__votestars">' + rateList + '</ul>';
                }

                if (product.price) {
                    isPromo = product.promotion_price !== null;
                    if (isPromo) {
                        resultInfo += '<span class="search__result-note-item search__result-item_active">' + product.promotion_price + '</span>';
                    }
                    resultInfo += '<span class="search__result-note-item ' + (isPromo ? 'search__result-item_inactive' : 'search__result-item_active') + '">' + product.price + '</span>';
                }

                if (!isPromo && product.availabilities && product.availabilities.can_buy && self.options.showProductsAvailability) {
                    resultInfo += '<span class="search__result-note-item">' + product.availabilities.label + '</span>';
                }

                if (product.options_stock) {
                    resultInfo += '<p class="search__result-details">' + Shop.lang.search.stock_price_info + '</p>';
                }

                return _resultTemplate({
                    resultType: 'Product',
                    url: product.url,
                    resultIcon: (product.img ? '<img src="' + product.img + '" alt="prduct image">' : '<span class="icon-no-image-icon search__icon_img-broken"></span>'),
                    resultNote: resultNote,
                    title: productName,
                    resultInfo: resultInfo
                });
            });

            return {
                $suggestRestriction: $suggestRestriction,
                markup: productsMarkup
            };
        },

        renderResults: function (options) {
            var self = this;
            var productsObj = {};
            var producersObj = {};
            var sortedResults = [];
            var $categoryMarkup;
            var $resultContainer;
            var regExp;
            var $suggestionsContainer;
            var areCategoryResticted;
            var isProductRestricted;
            var loadMoreText;
            /**
			 * contains element to which append restriction or after wchich (depends on suggestion existence)
			 */
            var $suggestRestritionHook;

            regExp = _getInputRegExp(this);

            this.parent.forEachIn(options.response.data, function (val, key) {
                sortedResults.push({
                    key: key,
                    data: val
                });
            });

            sortedResults.sort(function (a, b) {
                return b.data.max_score - a.data.max_score;
            });

            sortedResults.forEach(function (result) {
                if (result.key === 'products') {
                    if (result.data.list.length && result.data.list[0].category && options.firstSuggestion) {
                        isProductRestricted = true;
                    }

                    productsObj = self.getProductsMarkup(result.data, regExp, options);
                    _setPagesInfo(productsObj, result.data);
                } else if (result.key === 'producers') {
                    producersObj.markup = self.getProducersMarkup(result.data, regExp);
                    _setPagesInfo(producersObj, result.data);
                } else {
                    //categories
                    if (result.data.count) {
                        areCategoryResticted = true;
                        $categoryMarkup = self.getCategoryMarkup(result.data, regExp);
                    }
                }
            });

            $suggestionsContainer = this.elements.$resultSection.find('[data-search-section-type="' + Shop.Search.SEARCH_GLOBAL_SETTINGS.suggestions.id + '"]');
            if (areCategoryResticted || isProductRestricted) {
                if (!$suggestionsContainer.length) {
                    this.renderSuggestions({
                        response: {
                            data: [],
                            xhrPhrase: options.response.options.xhrPhrase
                        }
                    });

                    $suggestionsContainer = this.elements.$resultSection.find('[data-search-section-type="' + Shop.Search.SEARCH_GLOBAL_SETTINGS.suggestions.id + '"]');
                }

                /**
				 * At least one suggestion exist append restrition to it
				 */
                if (options.firstSuggestion) {
                    $suggestRestritionHook = $suggestionsContainer.find('.search__list-item:first-child');
                    if (areCategoryResticted) {
                        $suggestRestritionHook.after($categoryMarkup);
                    }

                    if (isProductRestricted) {
                        $suggestRestritionHook.after(productsObj.$suggestRestriction);
                    }
                } else {
                    $suggestRestritionHook = $suggestionsContainer.find('.search__list');
                    if (areCategoryResticted) {
                        $suggestRestritionHook.append($categoryMarkup);
                    }

                    if (isProductRestricted) {
                        $suggestRestritionHook.append(productsObj.$suggestRestriction);
                    }
                    $suggestionsContainer.removeClass(HIDE_CLASS);
                }
            }

            $resultContainer = this.elements.$resultSection;
            if (options.twoColumnResultsView) {
                $resultContainer = $('<div>', {
                    'class': 'search__item-container'
                }).appendTo(this.elements.$resultSection);
            }

            if (productsObj.markup && productsObj.markup.length) {
                loadMoreText = Shop.lang.search.more_products;

                if (!this.options.dynamicResultLoading) {
                    loadMoreText = this.parent.substitute(Shop.lang.search.go_to_products, {
                        name: options.firstSuggestion ? options.firstSuggestion : this.objects.search.inputValue
                    });
                }

                $resultContainer.append(this.objects.resultsProductsTemplate.template(_setTemplateSettings(this, productsObj, {
                    title: self.parent.substitute(Shop.lang.search.products_section_title, {
                        name: options.firstSuggestion ? options.firstSuggestion : this.objects.search.inputValue
                    }),
                    onListItemClick: options.onListItemClick,
                    loadMore: {
                        text: loadMoreText
                    },
                    xhrPhrase: options.response.options.xhrPhrase,
                    type: Shop.Search.SEARCH_GLOBAL_SETTINGS.products,
                    dynamicResultLoading: this.options.dynamicResultLoading,
                    staticResultLoading: !this.options.dynamicResultLoading,
                    firstSuggestion: options.firstSuggestion
                }), true));
            }

            if (producersObj.markup && producersObj.markup.length) {
                $resultContainer.append(this.objects.resultsProducersTemplate.template(_setTemplateSettings(this, producersObj, {
                    title: Shop.lang.search.producers,
                    onListItemClick: options.onListItemClick,
                    loadMore: {
                        text: Shop.lang.search.more_producers
                    },
                    xhrPhrase: options.response.options.xhrPhrase,
                    type: Shop.Search.SEARCH_GLOBAL_SETTINGS.producers,
                    dynamicResultLoading: this.options.dynamicResultLoading,
                    firstSuggestion: options.firstSuggestion
                }), true));
            }

            this.elements.$resultSection.removeClass(HIDE_CLASS);

            return this;
        },

        renderSuggestions: function (options) {
            var suggestText;
            var $suggestionsContainer;
            var regExpMatch;
            var regExp = _getInputRegExp(this);

            var suggestionsMarkup = options.response.data.map(function (suggest) {
                suggestText = suggest.toString();
                regExpMatch = false;

                if (regExp && regExp.test(suggestText)) {
                    regExpMatch = true;
                    suggestText = suggestText.replace(regExp, '<span class="search__result_match">$&</span>');
                }

                return {
                    markup:  _suggestTemplate(suggestText, regExpMatch, true),
                    attributes: {
                        'data-search-sugest-value': suggest
                    }
                };
            }, this);

            var $markup = this.objects.suggestionsTemplate.template({
                title: Shop.lang.search.suggestions,
                listItems: suggestionsMarkup,
                onListItemClick: options.onListItemClick,
                cssSectionClass: options.hide ? ' ' + HIDE_CLASS : ''
            }, true);

            $suggestionsContainer = this.elements.$resultSection;
            if (options.twoColumnResultsView) {
                $suggestionsContainer = $('<div>', {
                    'class': 'search__item-container'
                }).appendTo(this.elements.$resultSection);
            }

            $suggestionsContainer.append($markup);

            if (!options.hide) {
                this.elements.$resultSection.removeClass(HIDE_CLASS);
            }

            return this;
        },

        renderMessage: function (options) {
            var text = '';
            var cssClass = 'search__message_error';
            var self = this;

            switch (options.type) {
                case this.messagesTypes.emptyInputHistory:
                    text = Shop.lang.search.message_empty_input_history;
                    break;

                case this.messagesTypes.emptyInput:
                    text = Shop.lang.search.message_empty_input;
                    break;

                case this.messagesTypes.noResult:
                    text = Shop.fn.simpleSanitizeHTML(options.inputValue) + ' - <button class="search__btn_link">' + Shop.lang.search.message_no_result + '</button>';
                    cssClass = 'search__message_info';
                    break;
                case this.messagesTypes.searchError:
                    text = Shop.lang.search.error;
            }

            this.elements.$errorSection.removeClass(HIDE_CLASS).empty().append($('<p/>', {
                'class': 'search__message ' + cssClass,
                'html': text
            }));

            if (this.messagesTypes.searchError) {
                this.elements.$errorSection.find('.js__search-refresh-xhr').on('click', function () {
                    self.objects.search.sendRequest();
                });
            }

            return this;
        },

        moveSection: function (direction) {
            var $currentSection;
            var $newSection;
            var $currentResult;
            var sectionSelector;

            sectionSelector = '.' + SEARCH_SECTION_CLASS + ':not(.' + HIDE_CLASS + ')';
            $currentSection = this.elements.$content.find('.' + SEARCH_SECTION_ACTIVE_CLASS);
            $currentResult = this.elements.$content.find('.' + SEARCH_LIST_ITEM_ACTIVE_CLASS);

            if ($currentResult.length) {
                $currentResult.removeClass(SEARCH_LIST_ITEM_ACTIVE_CLASS);
            }

            if ($currentSection.length) {
                $newSection = direction === 'next' ? $currentSection.next(sectionSelector) : $currentSection.prev(sectionSelector);
                $currentSection.removeClass(SEARCH_SECTION_ACTIVE_CLASS);
            }

            if (!$currentSection.length || !$newSection.length) {
                $newSection = direction === 'next' ? this.elements.$content.find(sectionSelector).first() : this.elements.$content.find(sectionSelector).last();
            }

            $newSection.addClass(SEARCH_SECTION_ACTIVE_CLASS);
            this.moveResult(direction);

            return this;
        },

        moveResult: function (direction) {
            var $currentResult;
            var $currentSection;
            var $newResult;
            $currentResult = this.elements.$content.find('.' + SEARCH_LIST_ITEM_ACTIVE_CLASS);
            $currentSection = this.elements.$content.find('.' + SEARCH_SECTION_ACTIVE_CLASS);

            if ($currentResult.length) {
                $newResult = direction === 'next' ? $currentResult.next('.' + SEARCH_LIST_ITEM_CLASS + ':not(.' + HIDE_CLASS + ')') : $currentResult.prev('.' + SEARCH_LIST_ITEM_CLASS + ':not(.' + HIDE_CLASS + ')');
                $currentResult.removeClass(SEARCH_LIST_ITEM_ACTIVE_CLASS);
            } else {
                if ($currentSection.length) {
                    $newResult = direction === 'next' ? $currentSection.find('.' + SEARCH_LIST_ITEM_CLASS).first() : $currentSection.find('.' + SEARCH_LIST_ITEM_CLASS).last();
                }
            }

            if (!$currentSection.length || !$newResult.length) {
                if (this.elements.$content.find('.' + SEARCH_SECTION_CLASS).length) {
                    this.moveSection(direction);
                }

                return this;
            }

            if ($newResult.length) {
                this.getActiveSection().scrollTop($newResult.get(0).offsetTop - 70);
                $newResult.addClass(SEARCH_LIST_ITEM_ACTIVE_CLASS);

                if (typeof this.options.onResultMoved === 'function') {
                    this.options.onResultMoved($newResult);
                }
            }

            return this;
        }
    };

    function _getInputRegExp (searchViewInstance) {
        var regExp;

        var inputForRegExp = searchViewInstance.objects.search.inputValue.split(' ').filter(function (value) {
            return value.length > 1;
        }).map(function (value) {
            return Shop.fn.escapeRegExp(value);
        }).join('|');

        if (inputForRegExp.length) {
            regExp = new RegExp(inputForRegExp, 'i');
        }

        return regExp;
    }

    function _setTemplateSettings (searchViewInstance, obj, settings) {
        var loadMoreSettings = {};
        var currSettings = {
            title: settings.title,
            listItems: obj.markup,
            onListItemClick: settings.onListItemClick,
            inputValue: searchViewInstance.objects.search.inputValue
        };

        if (obj.page < obj.pages && settings.dynamicResultLoading) {
            loadMoreSettings = {
                loadMoreSettings: {
                    onLoadMoreClick: function (button) {
                        _handleDynamicLoadMoreClick(searchViewInstance, settings.type, button);
                    },
                    text: settings.loadMore.text
                },
                showLimit: Infinity,
                page: obj.page,
                pages: obj.pages,
                elementsCount: obj.count,
                xhrPhrase: settings.xhrPhrase
            };
        }

        if (settings.staticResultLoading) {
            loadMoreSettings = {
                loadMoreSettings:  {
                    onLoadMoreClick: function () {
                        searchViewInstance.objects.search.submitForm(settings.firstSuggestion);
                    },
                    text: settings.loadMore.text
                }
            };
        }

        return $.extend(currSettings, loadMoreSettings);
    }

    function _setPagesInfo (obj, info) {
        obj.page = info.page;
        obj.pages = info.pages;
        obj.count = info.count;

        return obj;
    }

    function _handleDynamicLoadMoreClick (searchViewInstance, type, button) {
        var $button = $(button);
        button.page += 1;

        if (button.page >= button.pages) {
            $button.remove();
        }

        searchViewInstance.objects.search.loadMoreResults({
            type: type,
            page: button.page,
            xhrPhrase: button.xhrPhrase,
            onError: function () {
                $button.remove();
            }
        });
    }

    function _checkMobileBtn () {
        if (!this.isOpen()) {
            if (document.body.scrollTop || document.documentElement.scrollTop > this.settings.mobileBtnOffsetVisible) {
                this.objects.mobileBtn.show();
            }  else {
                this.objects.mobileBtn.hide();
            }
        }
    }

    function _bindEvents (searchViewInstance) {
        var $window = $(window);

        if (searchViewInstance.objects.mobileBtn) {
            if (searchViewInstance.parent.rwd.xs) {
                $window.on('scroll', searchViewInstance.callbacks.checkMobileBtn);
            }

            $window.on('resize', searchViewInstance.parent.debounce(function () {
                if (searchViewInstance.parent.rwd.xs) {
                    searchViewInstance.objects.mobileBtn.create();

                    if (!searchViewInstance.isOpen()) {
                        searchViewInstance.objects.mobileBtn.show();
                    }
                    $window.on('scroll', searchViewInstance.callbacks.checkMobileBtn);
                } else {
                    searchViewInstance.objects.mobileBtn.destroy();
                    $window.off('scroll', searchViewInstance.callbacks.checkMobileBtn);
                }
            }, 200));
        }

        searchViewInstance.elements.$btnClose.on('click', function () {
            searchViewInstance.close();
        });

        searchViewInstance.elements.$search.find('.search__content').on('click', function (evt) {
            var $this = $(this);
            var isEmpty = true;

            if (searchViewInstance.parent.rwd.small && evt.target.classList.contains('search__content') && searchViewInstance.isOpen()) {

                /*
				* Check if any main section contains any children, if no can close
				*/
                $this.children().each(function (index, el) {
                    if (el.childNodes.length > 0) {
                        isEmpty = false;
                    }
                });

                if (isEmpty) {
                    searchViewInstance.close();
                }
            }
        });
    }

    function _cacheDOM (searchViewInstance) {
        searchViewInstance.elements.$content = searchViewInstance.elements.$search.find('.' + SEARCH_CONTENT_CLASS).eq(0);
        searchViewInstance.elements.$loader =  searchViewInstance.elements.$search.find('.search__loader');
        searchViewInstance.elements.$btnClear =  searchViewInstance.elements.$search.find('.js__search-clear-btn');
        searchViewInstance.elements.$btnClose =  searchViewInstance.elements.$search.find('.js__search-close-btn');
        searchViewInstance.elements.$input = searchViewInstance.elements.$search.find('.search__input');
    }

    function _suggestTemplate (value, resultMached, copyBtn) {
        var template = '';

        template = '<span class="js__search-suggest-item' + (resultMached ? ' search__result_bold' : '') + '">' + value + '</span>';

        if (copyBtn) {
            template += '<span role="button" aria-label="copy suggestion" class="js__search-btn-copy search__list-item-action-btn search__icon_main-color icon icon-arrow-skew"></span>';
        }

        return template;
    }

    function _resultTemplate (data) {
        return '<a data-search-result-type="' + (data.resultType || '') + '" href="' + data.url + '" class="search__result-link js__search-result-link">' +
			'<div class="search__result-icon ' + (data.resultIcon ? '' : ' none') + '">' +
				(data.resultIcon || '') +
			'</div>' +

			'<div class="search__result-content">' +
				'<div class="search__result-note">' +
					(data.resultNote || '') +
				'</div>' +

				'<div class="search__result-title">' +
					'<h5 class="search__result-title-item' + (data.cssTitle || '') + '">' +
						(data.title || '') +
					'</h5>' +
				'</div>' +

				'<div class="search__result-note">' +
					(data.resultInfo || '') +
				'</div>' +
			'</div>' +
		'</a>';
    }
})();