import {
    $emit
} from '../../../../core/tools/event_bus';
import {
    getBasketInformation
} from '../../../api/basket/index';
import {
    store
} from '../../store';

;
(function () {
    Shop.AjaxBasket.include({
        options: {
            form_selector: '.basket form[method=post][action], form.basket[method=post][action]',
            fullprod_selector: '#box_productfull',
            container_id: 'ajaxbasket',
            loyaltyexchange_class: 'loyaltyexchange',
            insert_px1: true,
            container: null,
            callbacks: {
                postAdd: null
            }
        },

        modal: null,
        json: null,
        mask: null,
        event: null,
        div: null,
        form: null,

        initialize: function (options) {
            var self;

            this.constructor.setOptions(options);

            if (this.options.container === null) {
                this.options.container = $('<div />');
                this.options.container.attr('id', this.options.container_id).addClass('none');
                this.options.container.appendTo($('body'));
            }

            self = this;
            this.parent.addEvent('cart:add', function (ev, evForm, options, $form) {
                var fp;

                if (!$form.hasClass(self.options.loyaltyexchange_class)) {
                    fp = $form.parents(self.options.fullprod_selector);

                    if (fp.length <= 0) {
                        options.preventSubmit = true;
                        self.wrapform($form[0], evForm);
                    }
                }
            });
        },

        wrapform: function (form, e) {
            var that = this,
                formAnswer = null,
                iframe = null,
                ajaxBasketHandler = null,
                fileInput;

            fileInput = $(form).find('input[type="file"]');

            if (fileInput.length > 0) {

                if (fileInput[0].files.length > 0) {
                    $('#box_productfull').addClass('loading');
                }

                iframe = $('<iframe />', {
                    'name': 'iframeUpload',
                    'css': {
                        display: 'none'
                    },
                    'src': 'about:blank',
                    'id': 'iframeUpload'
                }).appendTo(document.body);

                iframe = iframe[0];
                window.frames['iframeUpload'].name = 'iframeUpload';

                ajaxBasketHandler = function () {
                    $('#box_productfull').removeClass('loading');

                    if (iframe.detachEvent) {
                        iframe.detachEvent('onload', ajaxBasketHandler);
                    } else {
                        iframe.removeEventListener('load', ajaxBasketHandler, false);
                    }

                    if (iframe.contentDocument) {
                        formAnswer = iframe.contentDocument.body.innerHTML;
                    } else if (iframe.contentWindow) {
                        formAnswer = iframe.contentWindow.document.body.innerHTML;
                    } else if (iframe.document) {
                        formAnswer = iframe.document.body.innerHTML;
                    }

                    if (formAnswer) {
                        $.ajax({
                            url: $(form).attr('action'),
                            method: 'get',
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                        }).error(function () {
                            that.failure();
                        }).success(function (json) {
                            that.display(json);
                        });
                    } else {
                        that.failure();
                    }
                }

                if (iframe.addEventListener) {
                    iframe.addEventListener('load', ajaxBasketHandler, true);
                }

                if (iframe.attachEvent) {
                    iframe.attachEvent('onload', ajaxBasketHandler);
                }

                form.setAttribute('target', 'iframeUpload');
                form.submit();
            } else {
                this.parent.fireEvent('cart:preadd:ajax', form);

                if (!form.prevent) {
                    this.sendAjax(form);
                }

                return false;
            }
        },

        sendAjax: function (form) {
            var that = this;
            $.ajax({
                url: $(form).attr('action'),
                method: 'post',
                data: $(form).serialize()
            }).error(function () {
                that.failure();
            }).success(
                function (json) {
                    that.sent(json, form);
                    getBasketInformation({
                        lang: window.Shop.lang.name,
                        currency: window.Shop.values.currency
                    }).then((basket) => {
                        store.basketInfo = basket;
                        $emit('basket:update');
                    });
                });
        },

        sent: function (json, form) {
            if (typeof json === 'object') {
                this.json = json;
                if (!this.json.added || this.json.added.length == 0) {
                    this.error(form);
                } else {
                    this.display(undefined, form);
                }
            } else {
                this.failure();
            }
        },

        failure: function () {
            this.parent.alert(Shop.lang.ajaxbasket.submit_error);
        },

        error: function (form) {
            if (this.json.messages.error.length || this.json.messages.warning.length) {
                if (this.json.messages.error.length) {
                    if ($(form).parents('#box_productfull').length === 0) {
                        form.submit();
                    } else {
                        this.parent.alert(this.json.messages.error[0]);
                    }
                } else {
                    this.parent.alert(this.json.messages.warning[0]);
                }
            } else {
                this.failure();
            }
        },

        display: function (json, form) {
            if (json !== undefined) {
                this.json = json;
            }

            var shopClass = this.parent;
            var productCard;
            var productName;
            var productImg;
            var productCount;
            var productPriceBrutto;
            var productPriceNetto;
            var productPriceAllB;
            var productPriceAllN;
            var basketCount;
            var basketSummary;
            var basketSummaryDiscount;
            var ajaxBasketContainer;
            var productBlock;
            var productInfo;
            var cartBlock;
            var cartDiscountSpan;
            var stocks;
            var buttonBlock;
            var subAdd;
            var taxInfo;
            var x;
            var y;
            var z;
            var $h1;
            var $mainImg;
            var $quantity;
            var $priceGross;
            var $priceNet;
            var $taxInfo;

            if ($(form).parents('#box_productfull').length <= 0 && json === undefined) {
                subAdd = true;
                productCard = $(form).parents('.product');
            } else {
                subAdd = false;
                productCard = $('#box_productfull');
            }

            this.modal = new Shop.Modal();
            this.modal.options.header = $('<span />', {
                'text': Shop.lang.ajaxbasket.product_added
            });

            if (!subAdd) {
                $h1 = productCard.find('h1.name');
                if (!!$h1.length) {
                    productName = $h1.text();
                }

                $mainImg = productCard.find('.mainimg img');
                if (!!$mainImg.length) {
                    productImg = $mainImg.clone();
                }

                $quantity = productCard.find('input[name="quantity"]');
                if (!!$quantity.length) {
                    if (this.json.added[0]) {
                        productCount = this.json.added[0].quantity;
                    } else {
                        productCount = $quantity.val();
                    }
                }

                $priceGross = productCard.find('.price em');
                if (!!$priceGross.length) {
                    productPriceBrutto = $priceGross.text();
                }

                $priceNet = productCard.find('.price-netto em');
                if (!!$priceNet.length) {
                    productPriceNetto = $priceNet.text();
                }

                $taxInfo = productCard.find('.tax-additional-info .second');
                if (!!$taxInfo.length) {
                    taxInfo = $taxInfo.text();
                }
            } else {
                productName = $(productCard.find('span.productname')[0]).text();
                productImg = $(productCard.find('img')[0]).clone();
                productCount = productCard.find('[name="quantity"]').val();
                productPriceBrutto = $(productCard.find('.price > em')[0]).text();
                productPriceNetto = $(productCard.find('.price-netto > em')[0]).text();
                taxInfo = productCard.find('.tax-additional-info').text();

                if (!productPriceBrutto) {
                    productPriceBrutto = $(productCard.find('.price > div > em')[0]).text();
                }
            }

            basketCount = this.json.basket.count;
            basketSummary = this.json.basket.sum;
            basketSummaryDiscount = this.json.basket.sum_after_discounts;

            ajaxBasketContainer = $('<div />', {
                'class': 'ajax-basket-container'
            });

            productBlock = $('<div />', {
                'class': 'ajax-product-block'
            }).appendTo(ajaxBasketContainer);

            productImg.appendTo(productBlock);

            productInfo = $('<div />', {
                'class': 'ajax-product-block-info'
            }).appendTo(productBlock);

            $('<h3 />', {
                'text': productName
            }).appendTo(productInfo);

            $('<strong />', {
                'text': productCount
            }).appendTo(
                $('<span />', {
                    'text': Shop.lang.ajaxbasket.quantity + ':'
                }).appendTo(productInfo)
            );

            if (productCard.find('.price em').length > 0) {
                $('<strong />', {
                    'text': productPriceBrutto
                }).appendTo(
                    $('<span />', {
                        'text': (($('body').find('[data-netto]').length > 0) ? Shop.lang.ajaxbasket.netto_label : Shop.lang.ajaxbasket.brutto_label) + ':'
                    }).appendTo(productInfo)
                );
            }

            /*
            if (productCard.find('.tax-additional-info').length > 0) {
                $('<span />', {
                    'text': taxInfo,
                    'class': 'ajax-cart-tax-info'
                }).appendTo(productInfo)
            }
            */

            if (productCard.find('.price-netto em').length > 0) {
                $('<strong />', {
                    'text': productPriceNetto
                }).appendTo(
                    $('<span />', {
                        'text': Shop.lang.ajaxbasket.netto_label + ':'
                    }).appendTo(productInfo)
                );
            }

            stocks = $('<p />', {
                'class': 'ajax-stocks'
            }).appendTo(productInfo);

            if ($('.stocks').length > 0) {
                $('.stocks > .f-row').each(function () {
                    var label,
                        input,
                        value;

                    label = $(this).find('div.label label').text().replace('* ', '');
                    input = $(this).find('div.stock-options input, div.stock-options select');

                    switch (input.attr('type')) {
                        case 'file': {
                            value = input[0].files[0] ? input[0].files[0].name : '';
                            break;
                        }
                        case 'text': {
                            value = input.val();
                            break;
                        }
                        case 'radio': {
                            value = input.filter(':checked').parent().next().text();
                            break;
                        }
                        case 'checkbox': {
                            if (input.is(':checked')) {
                                value = input.next().attr('data-yes');
                            }
                            break;
                        }
                        case undefined: {
                            value = input.find(':selected').text();
                            break;
                        }
                    };

                    if (value) {
                        $('<strong />', {
                            'text': value
                        }).appendTo(
                            $('<span />', {
                                'text': label
                            }).appendTo(stocks)
                        );
                    }
                });
            }

            cartBlock = $('<div />', {
                'class': 'ajax-cart-block'
            }).appendTo(ajaxBasketContainer);

            $('<strong />', {
                'text': basketCount
            }).appendTo(
                $('<span />', {
                    'text': Shop.lang.ajaxbasket.products_number + ':'
                }).appendTo(cartBlock)
            );

            $('<strong />', {
                'text': basketSummary
            }).appendTo(
                $('<span />', {
                    'text': Shop.lang.ajaxbasket.basket_value + ':'
                }).appendTo(cartBlock)
            );

            if (basketSummary !== basketSummaryDiscount) {
                $('<strong />', {
                    'text': basketSummaryDiscount
                }).appendTo(
                    $('<span />', {
                        'text': Shop.lang.ajaxbasket.basket_value_discount + ':'
                    }).appendTo(cartBlock)
                );
            }

            this.modal.options.content = ajaxBasketContainer;

            buttonBlock = $('<div />', {
                'class': 'ajax-product-block'
            });

            $('<a />', {
                'class': 'btn left',
                'text': Shop.lang.ajaxbasket.continue_shopping
            }).on('click', this.modal, function (e) {
                e.data.hideModal();
                e.data._hideMask();

                setTimeout(e.data.destroyModal.bind(e.data), 600);
            }).appendTo(buttonBlock);

            $('<a />', {
                'class': 'btn btn-red right',
                'text': Shop.lang.ajaxbasket.goto_basket,
                'href': this.json.basket.url
            }).appendTo(buttonBlock);

            this.modal.options.footer = buttonBlock;

            this.modal.createModal();
            this.modal.reposition();
            this.modal.callbacks.close = function () {
                setTimeout(this.destroyModal.bind(this), 600);
            }

            if (typeof this.options.callbacks.postAdd === 'function') {
                this.options.callbacks.postAdd.call(this, arguments); // postAdd callback
            }

            this.parent.fireEvent('cart:add:ajax', $(form));
        },

        close: function () {
            if (!!this.div) this.div.remove();
            this.div = null;
            if (!!this.mask) this.mask.destroyM();
            this.mask = null;
            $('body').off('keyup', this.event);
        }
    });
})();
