type TChannel = {
    remove: () => void;
};

type TListener = {
    fn: (...params: Array<unknown>) => void;
    once: boolean;
};

const channels: {
    [key: string]: Array<TListener>;
} = {};

const hasOP = Object.prototype.hasOwnProperty;

/**
 *
 * @param {string} channel
 * @param {Function} callback - function to call on listened channel
 * @returns {object}
 */
export function $on(channel: string, fn: (...params: Array<unknown>) => void, once = false): TChannel {
    if (!hasOP.call(channels, channel)) {
        channels[channel] = [];
    }

    const listener: TListener = {
        fn,
        once
    };

    const index: number = channels[channel].push(listener) - 1;

    return {
        remove: () => {
            delete channels[channel][index];
        }
    };
}

/**
 *
 * @param {string} channel
 * @param {Function} callback - function to call on listened channel
 * @returns {object}
 */
export function $once(channel: string, fn: (...params: Array<unknown>) => void): TChannel {
    return $on(channel, fn, true);
}

/**
 *
 * @param {string} channel
 * @param {object} data
 */
export function $emit(channel: string, ...params: Array<unknown>): void {
    if (!hasOP.call(channels, channel)) {
        return;
    }

    channels[channel].forEach((listener, index) => {
        listener.fn(...params);

        if (listener.once) {
            delete channels[channel][index];
        }
    });
}

/**
 *
 * @param channel
 * @param listener - if not present, remove all attached listeners
 */
export function $off(channel: string, fn?: (...params: Array<unknown>) => void): void {
    if (!hasOP.call(channels, channel)) {
        return;
    }

    channels[channel] = channels[channel].filter((listener) => listener.fn !== fn);

    if (!channels[channel].length || !fn) {
        delete channels[channel];
    }
}
