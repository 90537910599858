import {
    ACCEPT_ALL_BUTTON_SELECTOR,
    ACCEPT_BUTTON_SELECTOR,
    ADVANCED_VIEW_SELECTOR,
    BASIC_VIEW_SELECTOR,
    CONSENT_CHECKBOX_SELECTOR,
    CONSENT_MODAL_ID,
    HIDE_CLASS,
    MASK_SELECTOR,
    POPUP_CLASS,
    SELECT_ALL_CONSENTS_SELECTOR,
    SHOW_ADVANCED_VIEW_SELECTOR,
    SHOW_BASIC_VIEW_SELECTOR
} from './constants';
import { CONSENT_MODAL_COUNTER, CONSENT_MODAL_KEY, LIST_OF_CONSENT_NAMES } from '../../constants';
import { checkCheckboxes, uncheckCheckboxes } from '@core/dom_utilities/forms';

import { $emit } from '@core/tools/event_bus';
import { AfterConsentsSaved } from './events/after_consents_saved';
import { CONSENT_MODAL_CREATED } from './event_names';
import { ConsentsApi } from '../consents_api';
import { TConsentName } from '../../types';
import { getTemplate } from '@core/dom_utilities/get';

export class ConsentsModal {
    private _api: ConsentsApi;
    private _modal: HTMLElement | null;
    private _modalMask: HTMLElement | null;

    constructor(api: ConsentsApi) {
        this._api = api;
        this._modal = null;
        this._modalMask = null;

        const userModalCounter = this.loadDisplayCounter();
        if (userModalCounter !== CONSENT_MODAL_COUNTER) {
            this._render();
            this._updateConsentCheckboxes();
            this._bindEvents();
            new AfterConsentsSaved(this);

            $emit(CONSENT_MODAL_CREATED, this);
        }
    }

    private _render(): void {
        this._modal = getTemplate(CONSENT_MODAL_ID)[0];
        this._modalMask = this._modal.querySelector(MASK_SELECTOR);
        document.body.appendChild(this._modal);
    }

    private _updateConsentCheckboxes(): void {
        this._api.getGrantedConsents().forEach((consent) => {
            const $consentCheckbox = document.querySelector(`input[name="${consent.getName()}"]`) as HTMLInputElement;
            $consentCheckbox.checked = true;
        });
    }

    private _bindEvents(): void {
        document.querySelector(ACCEPT_BUTTON_SELECTOR)?.addEventListener('click', this.saveConsentsChoosenByCustomer.bind(this));

        document
            .querySelector(ACCEPT_ALL_BUTTON_SELECTOR)
            ?.addEventListener('click', this._api.grantConsents.bind(this._api, LIST_OF_CONSENT_NAMES));

        document.querySelector(SHOW_BASIC_VIEW_SELECTOR)?.addEventListener('click', this.showBasicView.bind(this));
        document.querySelector(SHOW_ADVANCED_VIEW_SELECTOR)?.addEventListener('click', this.showAdvancedView.bind(this));
        document.querySelector(SELECT_ALL_CONSENTS_SELECTOR)?.addEventListener('change', this.toggleAllConsents.bind(this));
    }

    saveConsentsChoosenByCustomer(): void {
        const grantedConsents: TConsentName[] = [];
        const withdrawnConsents: TConsentName[] = [];
        const $checkboxConsents = [...document.querySelectorAll(CONSENT_CHECKBOX_SELECTOR)] as HTMLInputElement[];

        $checkboxConsents.forEach(($checkbox: HTMLInputElement) => {
            const consentName = $checkbox.name as TConsentName;
            if ($checkbox.checked) {
                grantedConsents.push(consentName);
            } else {
                withdrawnConsents.push(consentName);
            }
        });

        this._api.grantConsents(grantedConsents);
        this._api.withdrawConsents(withdrawnConsents);
    }

    showBasicView(): void {
        this._modal?.querySelector(BASIC_VIEW_SELECTOR)?.classList.remove(HIDE_CLASS);
        this._modal?.querySelector(ADVANCED_VIEW_SELECTOR)?.classList.add(HIDE_CLASS);
        this._modal?.classList.remove(POPUP_CLASS);

        if (this._modalMask) {
            this._modalMask.style.display = 'none';
        }
    }

    showAdvancedView(): void {
        this._modal?.querySelector(BASIC_VIEW_SELECTOR)?.classList.add(HIDE_CLASS);
        this._modal?.querySelector(ADVANCED_VIEW_SELECTOR)?.classList.remove(HIDE_CLASS);
        this._modal?.classList.add(POPUP_CLASS);

        if (this._modalMask) {
            this._modalMask.style.display = 'block';
        }
    }

    toggleAllConsents(ev: Event): void {
        const $checkbox = ev.target as HTMLInputElement;

        if ($checkbox.checked) {
            checkCheckboxes(LIST_OF_CONSENT_NAMES);
        } else {
            uncheckCheckboxes(LIST_OF_CONSENT_NAMES);
        }
    }

    saveAsDisplayed(): void {
        try {
            window.localStorage.setItem(CONSENT_MODAL_KEY, CONSENT_MODAL_COUNTER);
        } catch (err) {
            console.error(err);
        }
    }

    loadDisplayCounter(): string | null {
        return window.localStorage.getItem(CONSENT_MODAL_KEY);
    }

    close(): void {
        if (this._modal) {
            this._modal.remove();
        }
    }
}
