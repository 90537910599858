import Cookie from 'js-cookie';
import basketHeaderPreview from './basket_header_preview';
import basketBox from './basket_box';
import freeShippingBox from './free_shipping_box';

import { $on } from '@core/tools/event_bus';
import { store } from '@frontstoreRwd/store';
import { updateBasket } from './helpers';

export const basketPreview = {
    ...basketHeaderPreview,
    ...basketBox,
    ...freeShippingBox,

    async initialize() {
        const basketIndex = Cookie.get('basket');
        const update = () => this.update();

        this.basketHeaderPreviewInitialize();
        this.basketBoxInitialize();
        this.freeShippingBoxInitialize();

        if (basketIndex && basketIndex !== '0') {
            const lastBasketIndex = localStorage.getItem('basketIndex');
            const lastBasket = localStorage.getItem('frontstoreBasket');
            const hasBasketChanges = Boolean(lastBasketIndex && !(basketIndex === lastBasketIndex));

            if ((hasBasketChanges || !lastBasket) && store.basketInfo === null) {
                await updateBasket();
            } else {
                store.basketInfo = JSON.parse(String(lastBasket));
            }

            update();
        } else {
            localStorage.removeItem('basketIndex');
            localStorage.removeItem('frontstoreBasket');
        }

        $on('basket:update', update);
    },

    update() {
        this.updateHeaderBasketView();
        this.updateBoxBasketView();
        this.updateFreeShippingBoxView();
        this.setLocalBasketData();
    },

    setLocalBasketData() {
        try {
            localStorage.setItem('basketIndex', String(Cookie.get('basket')));
            localStorage.setItem('frontstoreBasket', JSON.stringify(store.basketInfo));
        } catch {}
    }
};
