export function getTemplate(templateId: string): HTMLElement[] {
    const template: HTMLTemplateElement = <HTMLTemplateElement>document.getElementById(templateId);
    const templateContent: DocumentFragment = template.content;

    // next line is Vue template v-pre fix
    const templateChildren = template.childNodes;
    const templateNodes: HTMLCollection = document.importNode(templateContent, true).children;
    const surveryTemplateHTMLElements: HTMLElement[] = <HTMLElement[]>[...templateNodes, ...templateChildren];

    return surveryTemplateHTMLElements;
}

export function getTemplateFromScriptTag(scriptID: string): string | null | undefined {
    return document.getElementById(scriptID)?.textContent;
}
