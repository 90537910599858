/**
 * set checked to all matching checkboxes
 * @param {string[]} checkboxNames - array of checkboxes names
 * @return {void}
 */
export function checkCheckboxes(checkboxNames: string[]): void {
    checkboxNames.forEach((name: string) => {
        [...document.querySelectorAll<HTMLInputElement>(`[name="${name}"], [data-related-name="${name}"]`)].forEach(
            ($checkbox: HTMLInputElement) => {
                $checkbox.checked = true;
            }
        );
    });
}

/**
 * unset checked to all matching checkboxes
 * @param {string[]} checkboxNames - array of checkboxes names
 * @returns {void}
 */
export function uncheckCheckboxes(checkboxNames: string[]): void {
    checkboxNames.forEach((name: string) => {
        [...document.querySelectorAll<HTMLInputElement>(`[name="${name}"], [data-related-name="${name}"]`)].forEach(
            ($checkbox: HTMLInputElement) => {
                $checkbox.checked = false;
            }
        );
    });
}
