import { store } from '@frontstoreRwd/store';
import { getBasketInformation } from '@frontstoreApi/basket';

export const doesBasketExist = () => Boolean(store.basketInfo);
export const areProductsInBasket = () => store.basketInfo && store.basketInfo.products.length > 0;
export const updateBasket = async () => {
    store.basketInfo = await getBasketInformation({
        // @ts-ignore
        lang: window.Shop.lang.name,
        // @ts-ignore
        currency: window.Shop.values.currency
    });
};
